.card {
    width: 100%;
    height: 19.4rem;

    background-size: 110%;
    background-position: left center;
    background-repeat: no-repeat;

    border-radius: 1.6rem;
    padding: 2rem;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    transition: background-position 2200ms ease;

    &:hover {
        background-position: right center;
    }

    .area {
        color: var(--c-white);
        font-size: var(--f-size-sm);
        font-weight: var(--f-semi-bold);
        text-transform: uppercase;
        letter-spacing: 0.05rem;
    }

    .plus-button svg {
        fill: var(--c-white);
    }
}
