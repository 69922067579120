.main {
    .intro {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 1.2rem;
        margin-top: -6rem;

        h1 {
            font-size: var(--f-size-2xl);
            font-weight: var(--f-semi-bold);
        }
    }

    .articles-list {
        display: flex;
        flex-direction: column;
        gap: 4rem;

        margin-top: 5.6rem;
    }
}
