.banners {
    margin-top: 12rem;

    .external-warning {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        margin-bottom: 1.6rem;

        p {
            width: calc(100% - 6rem);
            font-size: var(--f-size-xs);
            font-weight: var(--f-light);
            letter-spacing: 0.02rem;
            line-height: 1.6rem;
            margin: 0;
        }

        .icon {
            width: 2rem;
            fill: var(--c-magenta-500) !important;
        }

        &.dark {
            p {
                color: var(--c-magenta-200);
            }

            .icon {
                fill: var(--c-magenta-200) !important;
            }
        }

        &.light {
            p {
                color: var(--c-magenta-500);
            }

            .icon {
                fill: var(--c-magenta-500) !important;
            }
        }
    }
}
