.icon {
    position: relative;
    width: 7.2rem;
    height: 7.2rem;
    fill: var(--c-white);

    .lettering,
    .outerRing,
    .innerRing {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .rotate {
        -webkit-animation: rotate-center 12s linear infinite both;
        animation: rotate-center 12s linear infinite both;
    }

    .rotateReverse {
        -webkit-animation: rotate-center 12s linear infinite reverse both;
        animation: rotate-center 12s linear infinite reverse both;
    }
}

@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: translate(-50%, -50%) rotate(0);
        transform: translate(-50%, -50%) rotate(0);
    }
    100% {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
        -webkit-transform: translate(-50%, -50%) rotate(0);
        transform: translate(-50%, -50%) rotate(0);
    }
    100% {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
