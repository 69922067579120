.list {
    padding: 8.2rem 0;

    &.search {
        margin-top: -4rem;
        padding: 0;
    }

    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    .tag-container {
        width: 100%;
        overflow: scroll;
        padding-bottom: 2rem;
        margin-bottom: 3.2rem;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 0.4rem;
    }
}
