@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('./fonts/WorkSans-Light.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./fonts/WorkSans-Regular.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('./fonts/WorkSans-SemiBold.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('./fonts/WorkSans-Bold.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Source Code Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./fonts/SourceCodePro-Regular.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
    --f-main: 'Work Sans', sans-serif;
    --f-monospace: 'Source Code Pro', monospace;
    --f-light: 300;
    --f-regular: 400;
    --f-semi-bold: 600;
    --f-bold: 700;
    --f-size-xs: 1.3rem;
    --f-size-sm: 1.4rem;
    --f-size-md: 1.6rem;
    --f-size-lg: 2rem;
    --f-size-xl: 2.4rem;
    --f-size-2xl: 3.2rem;
    --f-size-3xl: 4.8rem;
    --f-size-4xl: 6.4rem;
}

html {
    font-size: 10px;
}

html,
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
    text-rendering: optimizeLegibility;
}

body,
input,
textarea,
select,
button {
    font-size: 1.6rem;
    font-family: var(--f-main);
}

body {
    font-family: var(--f-main);
    font-weight: var(--f-regular);
    line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--f-main);
    font-weight: var(--f-bold);
    line-height: 1.2;
}

h1 {
    font-size: 2.4rem;
}

h2 {
    font-size: 2.2rem;
}

h3 {
    font-size: 1.8rem;
}

p {
    line-height: 1.2;
}

select,
input,
button {
    line-height: 1;
}