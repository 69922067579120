.button {
    position: relative;
    color: var(--c-purple-600);
    font-family: var(--f-main);
    font-weight: var(--f-semi-bold);
    line-height: 1;
    letter-spacing: 0.04rem;
    cursor: pointer;

    background: var(--c-white);
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;

    overflow: hidden;
    z-index: 1;

    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;

    height: 3.6rem;
    padding: 0 1.6rem;
    font-size: var(--f-size-sm);
    border-radius: 1rem;

    svg path {
        fill: var(--c-purple-600);
    }

    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        width: 200%;
        height: 200%;
        background: var(--c-purple-600);
        opacity: 0.1;
        border-radius: 50%;
        filter: blur(5px);
        z-index: -1;
        transform: translateX(-50%);
        pointer-events: none;

        transition: top 300ms ease;
    }

    &:hover {
        transform: scale(1.01);

        &::after {
            top: -200%;
        }
    }

    &:focus {
        outline: 0.4rem solid var(--c-red-200);
    }

    &:disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.3;
    }

    &.md {
        height: 3.6rem;
        padding: 0 1.6rem;
        font-size: var(--f-size-sm);
        border-radius: 1rem;
    }

    &.lg {
        height: 5.2rem;
        padding: 0 3.6rem;
        font-size: var(--f-size-md);
        border-radius: 1.6rem;
    }

    &.full {
        width: 100%;
    }

    &.right {
        flex-direction: row-reverse;
    }

    &.solid {
        &.nutrition,
        &.movement,
        &.health {
            color: var(--c-white);

            svg path {
                fill: var(--c-white);
            }

            &::after {
                background: var(--c-white);
            }
        }

        &.nutrition {
            background: var(--c-magenta-500);
        }

        &.movement {
            background: var(--c-blue-400);
        }

        &.health {
            background: var(--c-purple-600);
        }
    }

    &.outline {
        background: transparent;
        color: var(--c-element);
        border: 1px solid var(--c-element);

        transition: background-color 300ms ease-in-out, color 300ms ease-in-out,
            border-color 300ms ease-in-out;

        &:hover {
            background: var(--c-element);
            color: var(--c-background);
            border-color: var(--c-element);
            transform: none;
        }

        &.nutrition {
            color: var(--c-magenta-300);
            border-color: var(--c-magenta-500);

            &:hover {
                background: var(--c-magenta-500);
                color: var(--c-white);
            }
        }

        &.movement {
            color: var(--c-blue-200);
            border-color: var(--c-blue-400);

            &:hover {
                background: var(--c-blue-400);
                color: var(--c-white);
            }
        }

        &.health {
            color: var(--c-purple-200);
            border-color: var(--c-purple-500);

            &:hover {
                background: var(--c-purple-500);
                color: var(--c-white);
            }
        }
    }
}

.rotate-center {
    -webkit-animation: rotate-center 5000ms linear infinite reverse forwards;
    animation: rotate-center 5000ms linear infinite reverse forwards;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-20 10:39:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
