.section {
    .intro-card {
        padding: 6.4rem 3.2rem;
        border-radius: 1.6rem;
        aspect-ratio: 1/1;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 0.8rem;

        background: radial-gradient(var(--c-blue-500), var(--c-purple-600));
        background-position: 0% 50%;
        background-size: 400% 400%;

        animation: Gradient 10000ms ease infinite;
        animation-play-state: running;

        h2,
        p {
            text-align: center;
            color: var(--c-white);
        }

        p {
            line-height: 2rem;
        }

        .emojis {
            align-self: stretch;

            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 0.8rem;

            font-size: 4.8rem;
        }
    }

    .image-placeholder {
        position: relative;
        width: 100%;
        height: 34rem;
        overflow: hidden;

        .user-image {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 13rem;

            .symbol {
                width: 6rem;
                height: 6rem;
                position: absolute;
                bottom: 1.6rem;
                right: 1.6rem;
                z-index: 1;
            }

            .footer {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            h2 {
                position: absolute;
                bottom: 1.2rem;
                left: 0;
                width: calc(100% - 6.5rem - 1.6rem);
                height: 5.6rem;
                padding: 0.8rem 1.6rem;
                overflow: hidden;

                display: flex;
                align-items: center;
                justify-content: flex-start;

                font-size: var(--f-size-sm);
                letter-spacing: 0.02rem;
                z-index: 1;
            }

            .url {
                position: absolute;
                bottom: 0.6rem;
                left: 1.6rem;
                z-index: 1;

                font-size: 1.1rem;
                letter-spacing: 0.06rem;
            }

            &.light,
            &.dark {
                h2 {
                    color: var(--c-white);
                }
            }
        }
    }

    .title-select {
        margin-top: 1.6rem;

        opacity: 0;
        pointer-events: none;
        transition: opacity 300ms ease-in-out;

        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        column-gap: 0.8rem;

        .input {
            flex: 1;
        }

        &.show {
            opacity: 1;
            pointer-events: all;
        }
    }

    .button-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;

        .image-select-button > div {
            background: var(--c-white);
        }
    }
}

@keyframes Gradient {
    0%,
    100% {
        background-position: 0% 50%;
        background-size: 300% 300%;
    }
    50% {
        background-position: 100% 50%;
        background-size: 600% 100%;
    }
}
