.button-container {
    position: relative;

    button {
        width: 4.2rem;
        height: 3rem;
        font-size: 1.8rem;
        background: transparent;
        color: var(--c-white);
        border: 1px solid var(--c-white);
        border-radius: 0.6rem;
    }

    .list {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        flex-direction: column;

        background: var(--c-white);
        border-radius: 0.6rem;

        box-shadow: 0 1rem 2.4rem -0.4rem rgba(0, 0, 0, 0.5);

        button {
            color: var(--c-bluish-purple-600);
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 3.6rem;
            height: 1px;
            background: var(--c-neutral-200);
        }
    }
}
