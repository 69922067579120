.dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;

    .backdrop {
        background-color: var(--c-bluish-purple-800);
        width: 100%;
        height: 100%;
    }

    .crop-container {
        position: fixed;
        top: 2.4rem;
        left: 2.4rem;
        right: 2.4rem;
        bottom: 8rem;

        .cropper-container {
            // .cropper-media {}

            .cropper-area {
                border: 2px solid var(--c-purple-300);
                border-radius: 2rem;
                color: rgba(40, 18, 64, 0.6);
            }
        }
    }

    .controls {
        position: fixed;
        bottom: 1rem;
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        .controls-upper-area {
            width: 100%;
            text-align: center;

            display: flex;
            align-items: center;
            justify-content: center;

            .slider {
                -webkit-appearance: none;
                width: 50%;
                background: transparent;

                &:focus {
                    outline: none;
                }

                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    border: 1px solid var(--c-green-400);
                    height: 1.6rem;
                    width: 1.6rem;
                    border-radius: 50%;
                    background: var(--c-green-400);
                    cursor: pointer;
                    margin-top: -0.6rem; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
                    box-shadow: 1px 1px 1px var(--c-neutral-800), 0px 0px 1px var(--c-neutral-800); /* Add cool effects to your sliders! */
                }

                &::-webkit-slider-runnable-track {
                    width: 100%;
                    height: 0.4rem;
                    cursor: pointer;
                    background: var(--c-purple-600);
                    border-radius: 0.4rem;
                }

                &:focus::-webkit-slider-runnable-track {
                    background: var(--c-green-400);
                }

                &::-moz-range-thumb {
                    border: 1px solid var(--c-green-400);
                    height: 1.6rem;
                    width: 1.6rem;
                    border-radius: 50%;
                    background: var(--c-green-400);
                    cursor: pointer;
                    box-shadow: 1px 1px 1px var(--c-neutral-800), 0px 0px 1px var(--c-neutral-800);
                }

                &::-moz-range-track {
                    width: 100%;
                    height: 0.4rem;
                    cursor: pointer;
                    background: var(--c-purple-600);
                    border-radius: 0.4rem;
                }

                &::-ms-thumb {
                    border: 1px solid var(--c-green-400);
                    height: 1.6rem;
                    width: 1.6rem;
                    border-radius: 50%;
                    background: var(--c-green-400);
                    cursor: pointer;
                    box-shadow: 1px 1px 1px var(--c-neutral-800), 0px 0px 1px var(--c-neutral-800);
                }

                &::-ms-track {
                    width: 100%;
                    height: 8.4px;
                    cursor: pointer;
                    background: transparent;
                    border-width: 16px 0;
                    border-color: transparent;
                    color: transparent;
                }

                &::-ms-fill-lower {
                    background: var(--c-purple-600);
                    border-radius: 0.4rem;
                }

                &:focus::-ms-fill-lower {
                    background: var(--c-purple-600);
                }

                &::-ms-fill-upper {
                    background: var(--c-purple-600);
                    border-radius: 0.4rem;
                }

                &:focus::-ms-fill-upper {
                    background: var(--c-purple-600);
                }
            }
        }

        .button-container {
            width: 100%;
            padding: 1.6rem;

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2rem;

            .button-cancel {
                border: 1px solid var(--c-purple-600);
                background: transparent;
                color: var(--c-white);
            }

            .button-save {
                background: var(--c-green-400);
            }
        }
    }
}
