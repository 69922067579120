.error-message {
    width: 100%;
    height: 100%;
    padding: 2.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    text-align: center;

    strong {
        font-size: var(--f-size-xl);
    }

    .repeat-button {
        margin-top: 5.6rem;
        background: transparent !important;

        &.dark {
            border: 1px solid var(--c-white);
            color: var(--c-white);
        }

        &.light {
            border: 1px solid var(--c-purple-300);
            color: var(--c-purple-600);
        }
    }
}
