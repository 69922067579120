.message {
    display: none;
}

@media screen and (min-width: 425px) {
    .message {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        .container {
            width: 90%;
            max-width: 28rem;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .logo {
                fill: var(--c-white);
                width: 12rem !important;
                height: 12rem;
                margin-bottom: 6rem;
            }

            & > * {
                color: var(--c-white);
                text-align: center;
            }

            h2 {
                font-size: 2.4rem;
            }

            .wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .qr-code {
                margin-top: 2rem;
                width: 10rem;
            }
        }

        .lang-button {
            position: absolute;
            top: calc(2rem + var(--safe-area-inset-top));
            right: 2rem;
            z-index: 1;
        }
    }
}
