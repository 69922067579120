.container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.8rem;

    .info-button {
        border: none;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
