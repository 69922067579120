.booking {
    margin-top: 6.4rem;

    h2 {
        margin-bottom: 1.8rem;
    }

    p {
        font-size: var(--f-size-sm);
        font-weight: var(--f-light);
        letter-spacing: 0.05rem;
    }

    .button {
        margin: 4.6rem auto 0;
        background: var(--c-green-500);
        color: var(--c-white);
    }
}
