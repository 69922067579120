.input-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12rem;
    height: 3.6rem;
    border-radius: 1rem;
    overflow: hidden;

    .label {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.4rem;

        width: 100%;
        height: 100%;

        font-size: var(--f-size-sm);
        font-family: var(--f-main);
        font-weight: var(--f-semi-bold);
        color: var(--c-purple-600);
        background: var(--c-green-200);
        pointer-events: none;

        svg {
            fill: var(--c-purple-600);
        }
    }

    .input-file {
        color: rgba(0, 0, 0, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        &::-webkit-file-upload-button {
            visibility: hidden;
        }

        &::before {
            height: 3.6rem;

            display: inline-flex;
            align-items: center;
            justify-content: center;

            border-radius: 1rem;
            padding: 0 1.6rem;
            outline: none;

            white-space: nowrap;
            user-select: none;
            cursor: pointer;

            font-size: var(--f-size-sm);
            font-family: var(--f-main);
            font-weight: var(--f-semi-bold);
            color: var(--c-purple-600);
            background: var(--c-green-200);
        }

        &.pt::before {
            content: 'Editar';
        }
        &.en::before {
            content: 'Edit';
        }

        &:hover::before {
            border-color: black;
        }

        &:active::before {
            background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
        }
    }
}
