.container {
    position: relative;
    width: 100%;
    height: 24rem;
    aspect-ratio: 16/9;
    overflow: hidden;

    .svg {
        position: absolute;
        bottom: -1.4rem;
        left: 40%;
        transform: translateX(-50%);
        width: 140%;
        box-sizing: border-box;
        path {
            fill: var(--c-header-background) !important;
        }
    }
}
