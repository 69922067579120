.points {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    gap: 1.6rem;
    padding: 4.8rem 0;

    .graph {
        transform: scale(0.8);
    }

    .button {
        align-self: center;
    }
}
