.container {
    width: 100%;

    .button {
        position: relative;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        background: transparent;
        border: none;
        font-size: var(--f-size-md);
        font-weight: var(--f-semi-bold);
        line-height: 1.4;
        padding: 0.8rem;

        span {
            text-align: left;
            width: calc(100% - 2.8rem);
        }

        svg {
            width: 2rem;
            height: 2rem;
            transform: rotate(180deg);
            transition: transform 300ms ease;

            &.open {
                transform: rotate(0deg);
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--c-white);
            opacity: 0;

            transition: opacity 200ms ease;
        }

        &:hover::after,
        &:focus::after {
            opacity: 0.1;
        }

        &:focus {
            outline: none;
        }
    }

    .content {
        overflow: hidden;
        transition: height 300ms ease, padding 300ms ease;
        margin-top: -1px;

        & > * {
            padding: 2rem;

            p {
                font-size: var(--f-size-md);
                font-weight: var(--f-light);
                color: var(--c-white);
                margin: 0;

                & + p {
                    margin-top: 0.8rem;
                }
            }
        }
    }

    & + .container .button {
        margin-top: -1px;
    }

    &.borders {
        .button {
            border-top: 1px solid var(--c-white);
            border-bottom: 1px solid var(--c-white);
        }

        .content {
            border-bottom: 1px solid var(--c-white);
        }
    }

    &.lg {
        button {
            font-size: var(--f-size-2xl);
            font-weight: var(--f-light);
        }
    }

    &.dark {
        .button {
            color: var(--c-white);

            svg {
                fill: var(--c-white) !important;
            }
        }
    }

    &.light {
        .button {
            color: var(--c-royal-blue-600);

            svg {
                fill: var(--c-royal-blue-600) !important;
            }
        }
    }
}
