.card {
    border-radius: 1rem;

    .button {
        padding: 1.6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.6rem;

        width: 100%;
        border: none;
        background: transparent;
        text-align: left;
        cursor: pointer;

        .wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1.2rem;

            .icon {
                margin-right: 1rem;
            }

            & > div {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 0.4rem;

                .title {
                    text-transform: uppercase;
                    font-size: var(--f-size-lg);
                }

                .status {
                    width: 100%;

                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    font-size: var(--f-size-sm);
                    letter-spacing: 0.05rem;

                    &::after {
                        content: '';
                        display: inline-block;
                        width: 1rem;
                        height: 1rem;
                        margin-top: 0.2rem;
                        margin-left: 0.4rem;
                        border-radius: 50%;
                    }

                    &.on::after {
                        background: var(--c-green-400);
                    }

                    &.off::after {
                        background: var(--c-royal-blue-600);
                    }
                }
            }
        }
    }

    .content {
        padding: 1.6rem;

        p,
        li {
            font-size: var(--f-size-sm);
            line-height: 1.4;

            & + p {
                margin-top: 1rem;
            }
        }

        ul {
            padding: 0.4rem 0 2rem 1.6rem;

            li + li {
                margin-top: 0.6rem;
            }
        }

        a {
            text-decoration: underline;
        }
    }

    &.dark {
        background: var(--c-bluish-purple-800);
        border: 1px solid var(--c-purple-700);

        .button {
            color: var(--c-white);

            .wrapper > div .status {
                color: var(--c-neutral-100);
            }

            svg {
                fill: var(--c-white) !important;
            }
        }

        .content {
            & * {
                color: var(--c-white);
            }
        }
    }

    &.light {
        background: var(--c-white);
        border: 1px solid var(--c-neutral-100);

        .button {
            color: var(--c-royal-blue-600);

            .wrapper > div .status {
                color: var(--c-royal-blue-600);
            }

            svg {
                fill: var(--c-royal-blue-600) !important;
            }
        }

        .content {
            & * {
                color: var(--c-royal-blue-600);
            }
        }
    }

    & + .card {
        margin-top: 1.6rem;
    }
}
