.intro {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0;
    margin-top: -6rem;

    .preTitle {
        font-size: var(--f-size-xs);
        color: var(--c-blue-200);
        font-weight: var(--f-semi-bold);
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: 0.05rem;
    }

    h1 {
        font-size: var(--f-size-2xl);
        font-weight: var(--f-semi-bold);
        text-align: center;
    }
}

.search {
    margin-top: 5.6rem;

    p {
        font-size: var(--f-size-sm);
        text-align: center;
        margin-bottom: 2.4rem;
    }

    .input {
        border-color: var(--c-blue-200);
    }
}

.faqs {
    margin-top: 8.2rem;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 4.2rem;
    }

    .accordion {
        .answer {
            p {
                line-height: 1.6;
            }

            ul {
                padding-left: 2rem;
                margin-top: 2rem;
                font-size: var(--f-size-md);
                font-weight: var(--f-light);

                li + li {
                    margin-top: 1rem;
                }
            }

            &.light {
                p {
                    color: var(--c-royal-blue-600);
                }

                ul li {
                    color: var(--c-royal-blue-600);
                }
            }
        }

        & + .accordion {
            margin-top: 2rem;
        }
    }
}

.formContainer {
    width: 100%;
    margin-top: 9rem;

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .modalButton {
            background: transparent;
            border: none;

            svg {
                width: 2rem;
                height: 2rem;
                transition: transform 300ms ease;
            }

            &.light {
                svg path {
                    fill: var(--c-royal-blue-600) !important;
                }
            }

            &.dark {
                svg path {
                    fill: var(--c-white) !important;
                }
            }
        }
    }

    & > .button {
        margin: 4.2rem auto;
    }

    .form {
        margin-top: 4.2rem;
        display: flex;
        flex-direction: column;
        gap: 1.6rem;

        .link {
            &.light {
                border-bottom: 1px solid var(--c-royal-blue-600);
            }

            &.dark {
                border-bottom: 1px solid var(--c-white);
            }
        }

        .button {
            margin: 1rem 0 0 auto;
        }
    }

    .sent-message {
        margin-top: 4.2rem;
        padding: 1.6rem;
        border-radius: 1rem;

        .button {
            margin: 3.2rem 0 0 auto;
        }

        &.dark {
            background: var(--c-bluish-purple-800);
            border: 1px solid var(--c-neutral-800);
        }

        &.light {
            background: var(--c-white);
            border: 1px solid var(--c-neutral-100);
        }
    }

    &.search {
        margin-top: 0;
    }
}

.modal {
    h2 {
        margin: 3.6rem 0 2.4rem;
        color: var(--c-white);
    }
    ol {
        padding-left: 1rem;
        margin-bottom: 2.4rem;

        li {
            color: var(--c-white);
            line-height: 1.4;

            & > * {
                display: block;
            }

            & + li {
                margin-top: 1rem;
            }
        }
    }
}
