.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 4rem auto 8rem;

    &.dark {
        .icon svg {
            fill: var(--c-white);
        }
    }

    &.light {
        .icon svg {
            fill: var(--c-purple-600);
        }
    }
}
