.cover {
    position: relative;
    height: calc(var(--safe-area-inset-top) + 18vw);
    min-height: 24.6rem;

    .title {
        position: absolute;
        top: calc(3rem + var(--safe-area-inset-top) / 2);
        left: 50%;
        transform: translateX(-50%);
        text-transform: uppercase;
        z-index: 1;
    }

    .logo {
        position: absolute;
        top: calc(1rem + var(--safe-area-inset-top) / 2);
        left: 50%;
        transform: translateX(-50%);
        width: 7.2rem;
        height: 7.2rem;
        fill: var(--c-white);

        &.no-image {
            width: 4.8rem;
            height: 4.8rem;

            &.light {
                fill: var(--c-purple-600);
            }
        }

        transition: top 200ms ease, width 200ms ease, height 200ms ease;
    }
}
