.card {
    width: 100%;
    position: relative;
    aspect-ratio: 1;
    background: var(--c-background);
    padding: 1.6rem;
    margin-bottom: 2rem;
    border-radius: 2rem;
    box-sizing: border-box;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .card-body {
        flex: 1;
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        figure {
            position: relative;
            width: 9.2rem;
            height: 9.2rem;
            margin: 0;

            .price {
                position: absolute;
                top: 0.8rem;
                right: -3.2rem;
                z-index: 2;
            }
        }

        .locations {
            line-height: 1;
            font-size: var(--f-size-xs);
            // white-space: nowrap;
        }

        h2 {
            line-height: 1;
            margin: 0;
            color: var(--c-blue-400);

            &.highlight {
                text-align: center;
            }
        }

        .platforms {
            line-height: 1;
            // white-space: nowrap;

            span {
                text-transform: uppercase;
                font-size: var(--f-size-xs);
            }
        }

        & > span {
            display: block;
        }

        .wrapper,
        .bioContent {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 0.8rem;

            margin-top: 1rem;
        }

        .rating {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            position: absolute;
            top: 2rem;
            left: 2rem;

            svg {
                width: 1.6rem;
                height: 1.6rem;
                opacity: 0.2;

                path {
                    fill: var(--c-blue-400);
                }

                &.on {
                    opacity: 1;
                }

                & + svg {
                    margin-left: 0.4rem;
                }
            }
        }

        .tags {
            width: 100%;
            margin-top: 1rem;
            height: 7.2rem;

            display: flex;
            align-items: center;
            justify-content: center;
            align-content: center;
            flex-wrap: wrap;
            gap: 0.8rem;

            .tagsCount {
                width: 2.2rem;
                height: 2.2rem;
                display: flex;
                align-items: center;
                justify-content: center;

                color: var(--c-blue-400);
                font-size: var(--f-size-sm);
                line-height: 0;
            }
        }
    }

    .card-footer {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .languages {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 0.4rem;

            span {
                color: var(--c-blue-400);
                border: 1px solid var(--c-blue-400);
                line-height: 0;
                width: 2.8rem;
                height: 2.8rem;
                border-radius: 0.8rem;
                font-size: 1.4rem;
                font-weight: var(--f-semi-bold);

                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .button svg {
            fill: var(--c-white);
        }
    }

    &.standard {
        aspect-ratio: unset;
        height: unset;
        gap: 2rem;

        .wrapper {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;

            figure {
                .price {
                    top: unset;
                    bottom: -1.5rem;
                    right: 50%;
                    transform: translateX(50%);
                }
            }

            .bioContent {
                width: calc(100% - 10.2rem);
                align-items: flex-start;
                padding-top: 3rem;
            }
        }

        .tags {
            margin-top: 2rem;
        }

        .rating {
            top: 2rem;
            left: 12rem;
        }
    }

    &.light {
        background: var(--c-white);
        border: 1px solid var(--c-neutral-100);

        .cardBody {
            .locations,
            .platforms {
                color: var(--c-neutral-800);
            }
        }
    }

    &.dark {
        background: var(--c-bluish-purple-900);
        border: 1px solid var(--c-purple-700);

        .cardBody {
            & > * {
                color: var(--c-white);
            }
        }
    }
}
