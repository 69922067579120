.card {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    border-radius: 0.8rem;

    & > div {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        padding: 1rem 2rem;
        box-sizing: border-box;

        & > span {
            color: var(--c-white);
            line-height: 1;
            font-size: var(--f-size-sm);

            &.points,
            &.date {
                font-weight: var(--f-semi-bold);
            }
        }

        &:first-of-type {
            justify-content: space-between;
            align-items: flex-start;
        }
        &:last-of-type {
            justify-content: center;
            align-items: center;
            border-left: 1px solid var(--c-white);
        }
    }

    &.movement {
        background: var(--c-blue-400);
        background-image: var(--g-gradient-movement-primary);
    }

    &.nutrition {
        background: var(--c-magenta-500);
    }

    &.health {
        background: var(--c-purple-600);
    }
}
