.firstScreen {
    h1 {
        text-align: center;
        margin-bottom: 2rem;
    }

    .lead {
        text-align: center;
        margin-bottom: 4rem;
    }

    .button {
        margin: 2rem auto;
    }

    .note {
        font-size: var(--f-size-xs);
        font-weight: var(--f-light);
        text-align: center;
    }

    .specs {
        margin-top: 12rem;

        h2 {
            margin: 6rem 0 1rem;
        }

        ol {
            padding-left: 2rem;
            margin-bottom: 6rem;

            li + li {
                margin-top: 1rem;
            }
        }

        .note {
            font-size: var(--f-size-xs);
            font-weight: var(--f-light);
            text-align: center;
            letter-spacing: 0.05rem;
        }

        .highlight {
            text-align: center;
            background: var(--c-purple-600);
            color: var(--c-white);
            margin: 8rem 0;
            padding: 1.6rem;
            border-radius: 1rem;
            letter-spacing: 0.01rem;
        }
    }
}

.intro {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0;
    margin-top: -6rem;

    .preTitle {
        font-size: var(--f-size-xs);
        color: var(--c-bluish-purple-200);
        font-weight: var(--f-semi-bold);
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: 0.05rem;
        margin-bottom: 0.5rem;
    }

    h1 {
        font-size: var(--f-size-2xl);
        font-weight: var(--f-semi-bold);
        text-align: center;
    }
}

.form {
    margin: 4rem auto;

    form {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .submit-btn {
            margin-top: 2rem;
        }
    }

    h3 {
        margin-top: 3.6rem;
    }

    .dataItems {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        gap: 0.4rem;

        .dataItem {
            display: grid;
            grid-template-columns: 1fr 1fr;

            span {
                font-size: var(--f-size-sm);
                letter-spacing: 0.05rem;
                padding: 0.4rem 0;

                &:first-of-type {
                    color: var(--c-purple-300);
                }
            }
        }

        &.dark .dataItem {
            border-bottom: 1px solid var(--c-neutral-800);

            &:first-of-type {
                border-top: 1px solid var(--c-neutral-800);
            }
        }

        &.light .dataItem {
            border-bottom: 1px solid var(--c-neutral-100);

            &:first-of-type {
                border-top: 1px solid var(--c-neutral-100);
            }
        }
    }
}

.success-msg {
    margin-top: 2rem;
    height: 26rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > * {
        text-align: center;
    }

    h2 {
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 4rem;
    }

    .icon {
        border: 1px solid var(--c-purple-300);
        width: 7.2rem;
        height: 7.2rem;
        aspect-ratio: 1;
        line-height: 1;
        border-radius: 1rem;
        margin: 1.6rem 0;
        padding: 2rem;

        &.dark {
            path {
                fill: var(--c-purple-300);
            }
        }

        &.light {
            path {
                fill: var(--c-purple-400);
            }
        }
    }
}
