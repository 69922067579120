.button {
    position: fixed;
    bottom: 2rem;
    right: -10rem;
    width: 3.6rem;
    height: 3.6rem;
    cursor: pointer;
    z-index: 1;

    border: none;
    line-height: 0;

    transition: right 200ms ease-out;

    &.show {
        right: 2rem;
    }

    &.light {
        background: var(--c-white);

        .icon {
            fill: var(--c-bluish-purple-900);
        }
    }

    &.dark {
        background: var(--c-purple-600);

        .icon {
            fill: var(--c-white);
        }
    }
}
