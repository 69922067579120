.subscriptions {
    h2 {
        text-align: center;
        margin-bottom: 3rem;
    }

    &.dark {
        h2 {
            color: var(--c-green-200);
        }
    }

    &.light {
        h2 {
            color: var(--c-green-400);
        }
    }

    .card {
        .logoContainer {
            margin: 2.4rem 0;
            padding: 1.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
