.container {
    .icon {
        aspect-ratio: 1;
        width: 100%;
        height: 100%;
    }

    .lettering {
        width: 100%;
        height: 100%;
        fill: var(--c-white);
    }

    &.showLettering.showSymbol {
        display: grid;
        grid-template-columns: 1.2fr 2fr;
        align-items: center;
        gap: 1rem;
    }
}
