$MOVEMENT_START_STROKE_DASHARRAY: 296%;
$MOVEMENT_END_STROKE_DASHARRAY: 595%;
$NUTRITION_START_STROKE_DASHARRAY: 371.8%;
$NUTRITION_END_STROKE_DASHARRAY: 250%;
$HEALTH_START_STROKE_DASHARRAY: 200.1%;
$HEALTH_END_STROKE_DASHARRAY: 395%;

.container {
    position: relative;
    width: 100%;
    height: 100%;

    .hearts {
        height: 100%;
        width: 100%;

        .heart {
            transform-origin: 50%;

            path {
                fill: none;

                &.progress {
                    opacity: 0;
                }
            }

            &.movement {
                .progress {
                    animation-delay: 1000ms;
                    stroke-dashoffset: 300%;
                    // stroke-dasharray: $MOVEMENT_START_STROKE_DASHARRAY;
                    // stroke-dasharray: $MOVEMENT_END_STROKE_DASHARRAY;
                }
            }

            &.nutrition {
                .progress {
                    animation-delay: 1200ms;
                    stroke-dashoffset: 500%;
                    // stroke-dasharray: $NUTRITION_START_STROKE_DASHARRAY;
                    // stroke-dasharray: $NUTRITION_END_STROKE_DASHARRAY;
                }
            }

            &.health {
                .progress {
                    animation-delay: 1400ms;
                    stroke-dashoffset: 200%;
                    // stroke-dasharray: $HEALTH_START_STROKE_DASHARRAY;
                    // stroke-dasharray: $HEALTH_END_STROKE_DASHARRAY;
                }
            }
        }
    }

    &.animate .hearts .heart {
        &.movement .progress {
            animation: progress-movement 1500ms ease-in-out forwards;
        }

        &.nutrition .progress {
            animation: progress-nutrition 1500ms ease-in-out forwards;
        }

        &.health .progress {
            animation: progress-health 1500ms ease-in-out forwards;
        }
    }
}

@keyframes progress-movement {
    0% {
        stroke-dasharray: $MOVEMENT_START_STROKE_DASHARRAY;
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes progress-nutrition {
    0% {
        stroke-dasharray: $NUTRITION_START_STROKE_DASHARRAY;
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes progress-health {
    0% {
        stroke-dasharray: $HEALTH_START_STROKE_DASHARRAY;
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
