.main {
    position: relative;

    .nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 1.6rem;
        background: var(--c-background);

        .nav-button {
            background: var(--c-magenta-500);

            &.dark {
                svg {
                    fill: var(--c-magenta-700);
                }
            }

            &.light {
                svg {
                    fill: var(--c-magenta-50);
                }
            }
        }
    }

    .input-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        gap: 1rem;
        margin-bottom: 1.6rem;

        &::after {
            position: absolute;
            bottom: 2rem;
            right: 1rem;

            font-size: var(--f-size-sm);
            font-weight: var(--f-semi-bold);
            letter-spacing: 0.1rem;
        }

        &.dark {
            &::after {
                color: var(--c-white);
            }
        }

        &.light {
            &::after {
                color: var(--c-royal-blue-600);
            }
        }

        &.weight::after {
            content: 'kg';
        }

        &.height::after {
            content: 'cm';
        }
    }

    .label {
        font-size: var(--f-size-xl);
        font-weight: var(--f-semi-bold);
    }

    .input,
    .fake-btn,
    .button {
        font-size: var(--f-size-md);
        color: var(--c-white);
        font-weight: var(--f-semi-bold);
        line-height: 1.2;
        text-align: center;

        height: 6.4rem;
        border: none;
        border-radius: 1rem;
        padding: 0 1.6rem;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: filter 300ms ease, background-color 300ms ease;

        &.dark {
            background: var(--c-bluish-purple-800);

            &:focus {
                outline: none;
                filter: brightness(1.5);
            }

            &.selected {
                background: var(--c-white);
                color: var(--c-bluish-purple-900);
            }
        }

        &.light {
            color: var(--c-royal-blue-600);
            background: var(--c-white);

            &:focus {
                outline: none;
                color: var(--c-magenta-800);
                background: var(--c-magenta-100);
            }

            &.selected {
                color: var(--c-white);
                background: var(--c-magenta-500);
            }
        }
    }

    .fake-btn {
        margin: 0.6rem 0 2.4rem;
    }

    .link {
        text-align: left;
        background: transparent;
        border: none;
        font-size: var(--f-size-xs);
        letter-spacing: 0.05rem;

        &.dark {
            color: var(--c-magenta-300);
        }

        &.light {
            color: var(--c-magenta-500);
        }

        &:focus {
            outline: none;
            text-decoration: underline;
        }
    }

    .button-link {
        position: relative;
        color: var(--c-white);
        font-family: var(--f-main);
        font-weight: var(--f-semi-bold);
        line-height: 1;
        letter-spacing: 0.04rem;
        cursor: pointer;

        background: var(--c-magenta-500);
        border: none;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.8rem;
        align-self: flex-end;

        overflow: hidden;
        z-index: 1;

        transition: transform 200ms ease-in-out;

        height: 3.6rem;
        padding: 0 1.6rem;
        font-size: var(--f-size-sm);
        border-radius: 1rem;
    }

    .push-right {
        align-self: flex-end;
    }

    .button-container {
        display: flex;
        justify-content: stretch;
        align-items: center;
        gap: 1.6rem;
        margin-bottom: 2rem;

        button {
            width: 100%;
        }
    }

    section {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        gap: 1rem;

        h2 {
            margin-bottom: 1rem;
        }
    }

    .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;

        width: 100%;
        padding: 1.6rem;
        aspect-ratio: 4.3;
        border-radius: 1rem;

        background: var(--g-gradient-nutrition-secondary), rgba(206, 182, 182, 0.1);

        h1 {
            text-align: center;
            color: var(--c-white);
        }
    }

    .logo-container {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 16rem;
        padding: 1rem;
        border-radius: 1rem;
        margin-bottom: 2rem;
        background: var(--c-bluish-purple-800);
    }

    .push-top {
        margin-top: 3rem;
    }

    .pack-image {
        border-radius: 1rem;
        margin-bottom: 1.6rem;
    }

    .modal {
        h2,
        p,
        li {
            color: var(--c-white);
        }

        h2 {
            margin-bottom: 2rem;
        }

        p + * {
            margin-top: 1.6rem;
        }

        .highlight {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 1.6rem 0;
            padding: 1.6rem;

            text-align: center;
            line-height: 1;
            color: var(--c-bluish-purple-400);
            background: var(--c-white);
        }

        ul {
            list-style-type: none;

            li {
                line-height: 1.2;

                b {
                    letter-spacing: 0.05rem;
                    text-transform: uppercase;
                }

                & + li {
                    margin-top: 1rem;
                }
            }
        }
    }
}
