.recent-articles {
    padding-top: 4.2rem;

    & > * {
        margin-bottom: 2rem;
    }

    h2 {
        margin-bottom: 2.4rem;
    }
}
