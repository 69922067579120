.header-secondary {
    position: fixed;
    top: -18rem;
    width: 100%;
    height: 14rem;
    padding-top: calc(var(--safe-area-inset-top) + 2rem);

    display: flex;
    align-items: flex-end;

    transition: top 300ms ease;

    .nav {
        position: relative;
        width: 96%;
        max-width: 34rem;
        height: 6.8rem;
        margin: 0 auto;
        padding: 1rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > a {
            width: 4.8rem;
            height: 4.8rem;
        }

        .back-btn {
            width: 4.8rem;
            height: 4.8rem;

            svg {
                width: 2.2rem;
                height: 2.2rem;
            }

            &.dark svg {
                fill: var(--c-purple-300);
            }

            &.light svg {
                fill: var(--c-purple-600);
            }
        }

        .title {
            position: absolute;
            top: calc(2rem);
            left: 50%;
            transform: translateX(-50%);
            text-transform: uppercase;
            z-index: 1;
        }

        .menu-button {
            &.light svg {
                fill: var(--c-bluish-purple-900);
            }

            &.dark svg {
                fill: var(--c-purple-300);
            }
        }
    }

    .logo {
        width: 4.8rem;
        height: 4.8rem;

        &.light {
            fill: var(--c-purple-600);
        }
    }

    &.scrolled {
        background: var(--c-background);
        top: calc(var(--safe-area-inset-top) - 7rem);
        margin-bottom: unset;
        z-index: 6;
    }

    &.dark {
        .logo {
            fill: var(--c-white);
        }

        &.scrolled {
            box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.7);
        }
    }

    &.light {
        .logo {
            fill: var(--c-purple-600);
        }

        &.scrolled {
            box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.18);
        }
    }
}
