.toggle-container {
    background: transparent;
    border: 0;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 5.2rem;
    height: 2rem;
    position: relative;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 1rem;

        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    &::after {
        content: '';
        width: 2rem;
        height: 2rem;
        border-radius: 1rem;

        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        transition: left 300ms ease, color 300ms ease;
    }

    &.selected::after {
        left: 64%;
    }

    &.dark {
        &::before {
            background: var(--c-bluish-purple-800);
            border: 1px solid var(--c-royal-blue-600);
            // box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 14%);
        }

        &::after {
            background: var(--c-royal-blue-600);
        }

        &.selected {
            &::before {
                border-color: var(--c-green-400);
            }

            &::after {
                background: var(--c-green-400);
            }
        }
    }

    &.light {
        &::before {
            background: var(--c-white);
            border: 1px solid var(--c-royal-blue-600);
            // box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 14%);
        }

        &::after {
            background: var(--c-royal-blue-600);
        }

        &.selected {
            &::before {
                border-color: var(--c-green-400);
            }

            &::after {
                background: var(--c-green-400);
            }
        }
    }
}
