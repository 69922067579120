.card {
    background: var(--c-blue-400);
    width: 100%;
    max-width: 16rem;
    aspect-ratio: 1;
    padding: 1.6rem 1.8rem;
    box-sizing: border-box;
    border-radius: 1.6rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    & > * {
        color: var(--c-white);
        text-align: center;
        margin: 0;
        line-height: 1;
        margin-bottom: 1.6rem;
    }

    .price {
        font-size: var(--f-size-3xl);
        font-weight: var(--f-light);

        span {
            font-size: var(--f-size-2xl);
        }
    }

    .category {
        text-transform: uppercase;
        font-weight: var(--f-semi-bold);
        margin-bottom: 0.4rem;
    }

    ul {
        list-style: none;
        padding: 0;
        height: 6rem;

        li {
            line-height: 1.2;
        }
    }

    a {
        color: var(--c-blue-400);
    }
}
