.link {
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: block;

    &:focus {
        outline: none;

        & > div {
            border-color: var(--c-red-200);
        }
    }
}

.card {
    width: 100%;
    max-width: 45rem;
    height: 34rem;
    aspect-ratio: 1;
    border-radius: 1.6rem;
    padding: 2.6rem 11rem 2.6rem 2.6rem;
    background: var(--c-purple-600);
    background-size: cover;
    background-position: left center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    border: 4px solid var(--c-background);

    margin-bottom: 2rem;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;

    transition: background-position 2200ms ease;

    & > span,
    h2,
    p {
        color: var(--c-white);
        margin: 0;
    }

    & > span {
        text-transform: uppercase;
        font-size: var(--f-size-md);
        line-height: 1;
    }

    h2 {
        flex: 1;
        font-size: var(--f-size-2xl);
    }

    p {
        font-size: var(--f-size-sm);
        width: 80%;
    }

    a {
        text-transform: unset;

        &.button {
            position: relative;
            font-family: var(--f-main);
            font-family: var(--f-main);
            font-weight: var(--f-semi-bold);
            line-height: 1;
            letter-spacing: 0.04rem;
            text-decoration: none;
            cursor: pointer;

            background: var(--c-white);
            border: none;

            display: inline-flex;
            align-items: center;
            justify-content: center;
            gap: 0.8rem;

            overflow: hidden;
            z-index: 1;

            transition: transform 200ms ease-in-out;

            height: 3.6rem;
            padding: 0 1.6rem;
            font-size: var(--f-size-sm);
            border-radius: 1rem;

            &::after {
                content: '';
                position: absolute;
                top: 100%;
                left: 50%;
                width: 200%;
                height: 200%;
                opacity: 0.1;
                border-radius: 50%;
                filter: blur(5px);
                z-index: -1;
                transform: translateX(-50%);

                transition: top 300ms ease;
            }

            &:hover {
                transform: scale(1.01);

                &::after {
                    top: -200%;
                }
            }

            &:focus {
                outline: 0.4rem solid var(--c-red-200);
            }

            &.nutrition,
            &.movement,
            &.health {
                color: var(--c-white);

                svg path {
                    fill: var(--c-white);
                }
            }

            &.nutrition {
                background: var(--c-magenta-500);
            }

            &.movement {
                background: var(--c-blue-400);
            }

            &.health {
                background: var(--c-purple-600);
            }
        }

        &:focus {
            outline: 4px solid var(--c-red-200);
        }
    }

    &:hover {
        background-position: right center;
    }

    &.dark {
        &.movement {
            & > span {
                color: var(--c-blue-400);
            }
        }

        &.nutrition {
            & > span {
                color: var(--c-magenta-500);
            }
        }

        &.health {
            & > span {
                color: var(--c-purple-600);
            }
        }

        h2,
        p {
            color: var(--c-neutral-800);
            margin: 0;
        }
    }
}
