.badge {
    position: relative;
    width: 6.6rem;
    height: 7.6rem;

    .wrapper {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        padding-top: 1.6rem;

        span {
            color: var(--c-white);
            font-weight: var(--f-semi-bold);
            line-height: 1;

            &.points {
                font-size: var(--f-size-lg);
            }

            &.label {
                font-size: 1rem;
                text-transform: uppercase;
                letter-spacing: 0.05rem;
            }
        }
    }
}
