.banner {
    margin: 1.6rem auto;
    border-radius: 1.6rem;
    padding: 3.2rem 1.6rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    row-gap: 0.8rem;

    background: radial-gradient(var(--c-blue-500), var(--c-purple-600));
    background-position: 0% 50%;
    background-size: 400% 400%;

    animation: Gradient 10000ms ease infinite;
    animation-play-state: running;

    .emojis {
        align-self: stretch;

        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 0.8rem;

        font-size: 4.8rem;
    }

    strong,
    span {
        text-align: center;
        color: var(--c-white);
    }

    strong {
        font-size: var(--f-size-lg);
    }

    .btn {
        margin-top: 1.6rem;
    }
}

@keyframes Gradient {
    0%,
    100% {
        background-position: 0% 50%;
        background-size: 300% 300%;
    }
    50% {
        background-position: 100% 50%;
        background-size: 600% 100%;
    }
}
