.section {
    padding: 8.2rem 0 0;

    h2 {
        text-align: center;
        margin-bottom: 3rem;
    }
}

.purple,
.green,
.blue {
    font-weight: var(--f-bold);
    font-size: var(--f-size-xl);
}

.card-list {
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;
}

.card {
    display: flex;
    align-items: flex-start;
    column-gap: 1.6rem;

    padding: 1.6rem;
    border-radius: 1rem;

    .icon {
        width: 5.4rem;
        height: 5.4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        border-radius: 0.8rem;

        span,
        strong {
            line-height: 1;
        }

        span {
            font-size: 1.6rem;
        }

        strong {
            font-size: 2.4rem;
        }
    }

    .content {
        flex: 1;

        display: flex;
        flex-direction: column;
        row-gap: 0.4rem;

        .date {
            color: var(--c-green-200);
            font-size: 1.3rem;
            text-transform: uppercase;
        }

        .title {
            font-size: 1.6rem;
        }

        .description {
            font-size: 1.4rem;
            font-weight: var(--f-light);
            line-height: 1.2;
        }
    }

    &.join-date {
        .icon {
            background: var(--c-green-500);
        }

        .content {
            .date {
                color: var(--c-green-200);
            }
        }
    }

    &.first-pay-date {
        .icon {
            background: var(--c-purple-300);
        }

        .content {
            .date {
                color: var(--c-purple-100);
            }
        }
    }

    &.monthly_pay-date {
        .icon {
            background: var(--c-blue-300);

            box-shadow: 0.2rem 0.2rem 0 var(--c-blue-500), 0.4rem 0.4rem 0 var(--c-blue-700);

            z-index: 1;
        }

        .content {
            .date {
                color: var(--c-blue-100);
            }
        }
    }
}

.dark {
    &.section {
        h2 {
            color: var(--c-green-200);
        }

        .purple,
        .green,
        .blue {
            color: var(--c-white);
        }

        .purple {
            background: var(--c-purple-300);
        }

        .green {
            background: var(--c-green-500);
        }

        .blue {
            background: var(--c-blue-300);
        }

        .card {
            background: var(--c-bluish-purple-800);
            border: 1px solid var(--c-purple-700);

            .icon {
                span,
                strong {
                    color: var(--c-white);
                }
            }
        }
    }
}

.light {
    &.section {
        h2 {
            color: var(--c-green-400);
        }

        .purple,
        .green,
        .blue {
            color: var(--c-white);
        }

        .purple {
            background: var(--c-purple-300);
        }

        .green {
            background: var(--c-green-500);
        }

        .blue {
            background: var(--c-blue-300);
        }

        .card {
            background: var(--c-white);
            border: 1px solid var(--c-neutral-100);

            .icon {
                span,
                strong {
                    color: var(--c-white);
                }
            }
        }
    }
}
