.toast {
    color: var(--c-toast-element);
    background: var(--c-toast-background);

    & > button svg {
        fill: var(--c-toast-close-button);
    }

    .toast-progress {
        background: var(--c-toast-progress);
    }
}
