.search {
    padding: 8rem 0;

    .input {
        border-color: var(--c-blue-200);

        &:focus {
            border-color: var(--c-white);
        }
    }
}
