.card {
    width: 100%;
    max-width: 16rem;
    box-sizing: border-box;
    padding: 1.6rem 1.8rem;
    border-radius: 1.6rem;
    aspect-ratio: 3/4;
    position: relative;

    display: grid;
    grid-template-rows: 3fr 1fr;
    gap: 2rem;

    img {
        width: 100%;
        height: 14rem;
        object-fit: contain;
    }

    button {
        margin: 0 auto;
    }

    &.light {
        background: var(--c-white);
    }

    &.dark {
        background: var(--c-neutral-100);
    }
}
