.card {
    position: relative;
    width: 100%;
    max-width: 400;
    display: grid;
    grid-template-areas:
        'title'
        'subtitle'
        'image'
        'description';
    gap: 0.4rem;

    background: var(--c-background);
    border: none;
    border-radius: 1rem;
    cursor: pointer;
    text-decoration: none;
    padding: 1.6rem 0;
    box-sizing: border-box;

    transition: filter 1000ms ease;

    .title,
    .subtitle,
    .description {
        text-align: left;
        margin: 0;
    }

    .title,
    .description {
        color: var(--c-element);
    }

    .title {
        grid-area: title;
        font-size: var(--f-size-md);
        font-weight: var(--f-semi-bold);
    }

    .subtitle {
        grid-area: subtitle;
        font-size: var(--f-size-xs);
        text-transform: uppercase;
    }

    .description {
        grid-area: description;
        font-weight: var(--f-light);
        font-size: var(--f-size-sm);
        line-height: 1.4;
        letter-spacing: 0.05rem;
    }

    .image-container {
        grid-area: image;

        position: relative;
        margin: 0.4rem 0;
        width: 100%;
        height: 22rem;
        aspect-ratio: 1.618; // golden ratio
        border-radius: 1rem;
        box-sizing: border-box;
        overflow: hidden;

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        background: var(--c-purple-600);

        img {
            position: absolute;
            width: 105%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            transition: filter 1000ms ease, transform 1600ms ease;
        }
    }

    &:hover,
    &:focus {
        filter: brightness(1.4);
        outline: 1px solid rgba(255, 130, 128, 0.2);

        img {
            filter: brightness(0.8);
            transform: translate(-50%, -50%) scale(1.03);
        }
    }

    &.articles .subtitle {
        color: var(--c-yellow-200);
    }

    &.movement .subtitle {
        color: var(--c-blue-400);
    }

    &.health .subtitle {
        color: var(--c-purple-300);
    }

    &.nutrition .subtitle {
        color: var(--c-magenta-400);
    }

    &.alternative {
        grid-template-areas:
            'image'
            'title'
            'subtitle'
            'description';
        padding: 0 0 1.6rem 0;

        .imageContainer {
            margin: 0 0 0.4rem 0;
        }
    }

    &.sm {
        display: grid;
        grid-template-columns: 1.2fr 2fr;
        grid-template-rows: 1fr auto;
        grid-template-areas:
            'image description'
            'image subtitle';
        gap: 1rem;

        .title {
            display: none;
        }

        img {
            aspect-ratio: 4/3;
        }
    }
}
