.gym-log {
    position: relative;
    padding: 4.8rem 0 0;

    h2 {
        margin-bottom: 1.6rem;
    }

    p {
        font-size: var(--f-size-sm);
        line-height: 1.2;

        & + p {
            margin-top: 0.8rem;
        }
    }

    .card-list {
        margin-top: 3.2rem;

        .card + .card {
            margin-top: 1rem;
        }
    }

    .link {
        position: absolute;
        bottom: -4rem;
        right: 1rem;
        z-index: 1;
    }

    .noActivity {
        background-image: var(--g-gradient-movement-primary);
        padding: 2.4rem 1.6rem;
        border-radius: 1rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        strong,
        p {
            text-align: center;
            line-height: 1.2;
        }

        strong {
            margin-bottom: 1rem;
            letter-spacing: 0.05rem;
            font-size: var(--f-size-lg);
        }

        p {
            margin: 0;
            font-size: var(--f-size-md);
        }
    }

    &.noRecords::after {
        content: unset;
    }
}
