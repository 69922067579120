.account-status {
    padding: 8.2rem 0 0;

    h2 {
        text-align: center;
        margin-bottom: 3rem;
    }

    &.dark h2 {
        color: var(--c-green-200);
    }

    &.light h2 {
        color: var(--c-green-400);
    }

    .card {
        padding: 1.6rem;
        border-radius: 1rem;

        header {
            height: 10rem;
            padding: 0 2.4rem;
            border-radius: 1rem;

            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0.8rem;

            .wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                gap: 0.2rem;

                .title {
                    font-size: var(--f-size-2xl);
                    font-weight: var(--f-light);
                    text-transform: uppercase;
                    line-height: 1;
                    color: var(--c-white);
                }

                .date {
                    font-size: var(--f-size-sm);
                    text-transform: uppercase;
                    letter-spacing: 0.1rem;
                    font-family: var(--f-monospace);
                    line-height: 1;
                }
            }

            .icon {
                width: 6.4rem;
                height: 6.4rem;
                padding: 2.2rem;

                display: flex;
                align-items: center;
                justify-content: center;

                background: var(--c-white);
                border-radius: 50%;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        footer {
            margin-top: 1.6rem;

            .message {
                width: 100%;
                display: block;
                font-size: var(--f-size-sm);
                line-height: 1.4;
                text-align: center;
            }

            .payment {
                margin-top: 2.4rem;

                ul {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin: 2.4rem 0;

                    li {
                        font-size: var(--f-size-sm);

                        width: 70%;
                        list-style-type: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        strong {
                            font-family: var(--f-monospace);
                            letter-spacing: 0.08rem;
                        }

                        & + li {
                            margin-top: 1.2rem;
                        }
                    }
                }

                .note {
                    display: flex;
                    font-size: var(--f-size-xs);
                    line-height: 1.2;
                    letter-spacing: 0.02rem;
                }
            }
        }

        &.dark {
            background: var(--c-bluish-purple-800);
            border: 1px solid var(--c-purple-700);

            &.ok header {
                background: var(--g-gradient-movement-secondary);

                .date {
                    color: var(--c-green-300);
                }

                .icon {
                    background: var(--c-green-500);

                    svg {
                        fill: var(--c-white) !important;
                        stroke: var(--c-white);
                        stroke-width: 0.5px;
                    }
                }
            }
            &.with-debt header {
                background: var(--g-gradient-nutrition-secondary);

                .date {
                    color: var(--c-magenta-200);
                }

                .icon {
                    background: var(--c-magenta-500);

                    svg {
                        fill: var(--c-white) !important;
                        stroke: var(--c-white);
                        stroke-width: 0.5px;
                    }
                }
            }

            footer {
                .payment {
                    li {
                        border-bottom: 1px solid var(--c-purple-700);
                    }

                    .note {
                        color: var(--c-purple-300);
                    }
                }
            }
        }

        &.light {
            background: var(--c-white);
            border: 1px solid var(--c-neutral-100);

            &.ok header {
                background: var(--g-gradient-movement-primary);

                .date {
                    color: var(--c-blue-600);
                }

                .icon {
                    background: var(--c-blue-500);

                    svg {
                        fill: var(--c-white) !important;
                        stroke: var(--c-white);
                        stroke-width: 0.5px;
                    }
                }
            }

            &.with-debt header {
                background: var(--g-gradient-nutrition-primary-alt);

                .date {
                    color: var(--c-magenta-50);
                }

                .icon {
                    background: var(--c-magenta-500);

                    svg {
                        fill: var(--c-white) !important;
                        stroke: var(--c-white);
                        stroke-width: 0.5px;
                    }
                }
            }

            footer {
                .payment {
                    li {
                        border-bottom: 1px solid var(--c-neutral-50);
                    }
                }

                .note {
                    color: var(--c-purple-300);
                }
            }
        }
    }
}
