.container {
    position: relative;
    width: 100%;
    height: 100%;

    .rings {
        height: 100%;
        width: 100%;

        .ring {
            transform-origin: 50%;

            circle {
                fill: none;

                &.completed {
                    stroke-linecap: round;
                    opacity: 0;
                }
            }

            &.nutrition {
                transform: scale(1) rotate(-90deg);

                .completed {
                    animation-delay: 1000ms;
                }
            }

            &.movement {
                transform: scale(0.8) rotate(-90deg);

                .completed {
                    animation-delay: 1200ms;
                }
            }

            &.health {
                transform: scale(0.6) rotate(-90deg);

                .completed {
                    animation-delay: 1400ms;
                }
            }
        }
    }

    &.animate .rings .ring .completed {
        animation: ringProgress 1500ms ease-in-out forwards;
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 12.5rem;
        height: 12rem;
        transform: translate(-50%, -50%);

        &.movement {
            transform: translate(-50%, -60%);
        }

        &.nutrition {
            transform: translate(-50%, -60%);
        }
    }

    .score {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        display: flex;
        align-items: center;
        justify-content: center;

        margin-top: -1rem;

        line-height: 0;
        font-size: var(--f-size-3xl);
        font-weight: var(--f-light);
        letter-spacing: 0.05rem;

        span {
            font-size: var(--f-size-xl);
            font-weight: var(--f-regular);
            margin-top: -1.6rem;
        }

        &.movement,
        &.nutrition {
            transform: translate(-50%, -48%);
        }
    }

    .label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        font-family: var(--f-main);
        color: var(--c-white);
        font-size: var(--f-size-md);
        font-weight: var(--f-semi-bold);
        line-height: 0;
        letter-spacing: 0.1rem;

        padding: 2.2rem;
        border-radius: 1rem;
        background-color: var(--c-blue-400);

        z-index: 2;

        &.movement {
            background-color: var(--c-blue-400);
        }

        &.health {
            background-color: var(--c-purple-600);
        }

        &.nutrition {
            background-color: var(--c-magenta-500);
        }
    }

    &.dark {
        .rings .ring circle.background {
            stroke: var(--c-bluish-purple-900);
            // filter: brightness(0.8);
        }

        .icon path {
            fill: var(--c-bluish-purple-800);
            // filter: brightness(0.7);
        }

        .score {
            color: var(--c-white);
        }
    }

    &.light {
        .rings .ring circle.background {
            stroke: var(--c-neutral-50);
            // filter: brightness(0.98);
        }

        .icon path {
            fill: var(--c-white);
        }

        .score {
            color: var(--c-bluish-purple-900);
        }
    }
}

@keyframes ringProgress {
    0% {
        stroke-dasharray: 0 100;
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
