.success-message {
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    .wrapper {
        width: 90%;
        max-width: 32rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;

        & > * {
            text-align: center;
        }

        .shared-image {
            transform: rotate(-7deg);
            padding: 4rem;
            border-radius: 5rem;

            img {
                border-radius: 2rem;
                overflow: hidden;
                box-shadow: var(--c-purple-500) 0 -1px 4px, var(--c-purple-600) 0 -2px 10px,
                    var(--c-purple-600) 0 -10px 20px, var(--c-magenta-600) 0 -18px 40px,
                    5px 5px 15px 5px rgba(0, 0, 0, 0);
            }

            &.scale-in-center {
                -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
                animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            }
        }
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-13 19:53:28
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0) rotate(-100deg);
        transform: scale(0) rotate(-100deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1) rotate(-7deg);
        transform: scale(1) rotate(-7deg);
        opacity: 1;
    }
}
@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0) rotate(-100deg);
        transform: scale(0) rotate(-100deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1) rotate(-7deg);
        transform: scale(1) rotate(-7deg);
        opacity: 1;
    }
}
