.container {
    position: relative;
    width: 100%;
    height: 26.4rem;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .svg {
        position: absolute;
        bottom: -1rem;
        left: 50%;
        transform: translateX(-50%);
        fill: var(--c-background) !important;
    }
}
