.errorButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 3rem;
    height: 3rem;

    background: transparent;
    border: none;
    cursor: pointer;
    opacity: 0;
    transform: scale(0.2);

    display: flex;
    align-items: center;
    justify-content: center;

    transition: opacity 200ms ease, transform 200ms ease;
    z-index: 1;

    path {
        fill: var(--c-white);
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--c-red-200);
        border-radius: 50%;
        opacity: 0.5;
        z-index: -1;

        transition: opacity 200ms ease;
    }

    &:hover {
        path {
            fill: var(--c-white);
        }

        &::after {
            opacity: 0.8;
        }
    }

    &:focus {
        outline: 0.4rem solid var(--c-red-200);
        border-radius: 50%;
    }

    &.error {
        opacity: 1;
        transform: scale(1);
    }

    &.topRight {
        top: 0;
    }

    &.centerRight {
        top: 50%;
        transform: translateY(-50%);
    }

    // &.light {
    //     &::after {
    //         box-shadow: 0 0 8px 40px var(--c-white);
    //     }
    // }

    // &.dark {
    //     &::after {
    //         box-shadow: 0 0 60px 60px var(--c-bluish-purple-900);
    //     }
    // }
}
