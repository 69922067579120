.splash {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: calc(12rem + var(--safe-area-inset-top)) 0 2rem 0;

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8rem;

        .logo {
            transform: scale(1.5);
        }
    }

    p,
    span {
        text-align: center;
    }

    span {
        font-size: var(--f-size-xs);
        letter-spacing: 0.1rem;
        color: var(--c-purple-300);
    }
}
