.react-calendar {
    max-width: 600px;
    margin-bottom: 2rem;
}

.react-calendar__navigation {
    margin-bottom: 1rem;
    height: 3.6rem;

    display: flex;
    align-items: stretch;
    justify-content: flex-end;
}

.react-calendar__navigation button {
    background: transparent;
    border: none;
    cursor: pointer;
    width: 3.6rem;
    height: 3.6rem;
}

.react-calendar__navigation button svg {
    width: 1.8rem;
    height: 1.8rem;
}

.dark .react-calendar__navigation button svg {
    fill: var(--c-white);
}
.light .react-calendar__navigation button svg {
    fill: var(--c-purple-600);
}

.react-calendar__navigation button:disabled svg {
    cursor: auto;
}
.dark .react-calendar__navigation button:disabled svg {
    fill: var(--c-purple-600);
}

.light .react-calendar__navigation button:disabled svg {
    fill: var(--c-purple-100);
}

.react-calendar__navigation__label {
    font-size: 1.8rem;
    font-weight: var(--f-semi-bold);
}

.react-calendar__month-view {
    min-height: 28.4rem;
}

.react-calendar__month-view__weekdays {
    margin-bottom: 0.6rem;
}

.react-calendar__month-view__weekdays__weekday {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    color: var(--c-white);
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}

.react-calendar__month-view__weekdays__weekday--weekend {
    color: var(--c-purple-200);
}

.react-calendar__month-view__days__day {
    background: transparent;
    height: 4.2rem;
    border: none;
    border-radius: 0.8rem;
    cursor: auto;
}
.dark .react-calendar__month-view__days__day {
    color: var(--c-white);
}
.light .react-calendar__month-view__days__day {
    color: var(--c-purple-600);
}

.dark .react-calendar__month-view__days__day--weekend {
    color: var(--c-purple-200);
}
.light .react-calendar__month-view__days__day--weekend {
    color: var(--c-purple-200);
}

.dark .react-calendar__month-view__days__day--neighboringMonth {
    color: var(--c-purple-600);
}

.light .react-calendar__month-view__days__day--neighboringMonth {
    color: var(--c-purple-100);
}
