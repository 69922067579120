.manual-log {
    margin-top: 10rem;

    .header {
        display: grid;
        grid-template-columns: calc(100% - 4.8rem - 1rem) 4.8rem;
        gap: 1rem;
        align-items: flex-end;

        .wrapper {
            h2 {
                margin-bottom: 1.6rem;
            }

            p {
                font-size: var(--f-size-sm);
            }
        }

        .share-button {
            width: 4.8rem;
            height: 4.8rem;

            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 1.6rem;

            svg {
                width: 2.8rem;
                height: 2.2rem;
                fill: var(--c-purple-300);
            }

            &.dark {
                border: 1px solid var(--c-purple-600);
                background: var(--c-purple-800);
            }

            &.light {
                border: 1px solid var(--c-purple-100);
                background: var(--c-purple-50);
            }
        }
    }

    .manual-log-loading {
        width: 100%;
        min-height: 34rem;
        background-image: var(--g-gradient-health-secondary);
        border-radius: 2rem;
    }
}
