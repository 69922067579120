.section {
    h1 {
        margin-bottom: 1rem;
    }
    p {
        font-size: var(--f-size-sm);
    }

    .card {
        background: var(--c-white);
        border-radius: 0.8rem;
        padding: 3.2rem 1.6rem;
        margin: 3rem 0;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        gap: 1rem;

        form {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;
            gap: 1rem;

            .input-password {
                margin: 1.6rem 0;
            }
        }

        .button {
            align-self: flex-end;

            &.center {
                align-self: center;
            }
        }

        .avatar {
            width: 16rem;
            align-self: center;
        }

        &.toggle {
            flex-direction: row;
            justify-content: space-between;

            .label {
                font-size: var(--f-size-lg);
                font-weight: var(--f-semi-bold);
            }
        }

        &.dark {
            background: var(--c-bluish-purple-800);
            color: var(--c-white);
            box-shadow: 0 5px 12px -6px var(--toastify-color-dark),
                5px 5px 15px 5px rgba(0, 0, 0, 0);
        }

        &.light {
            background: var(--c-white);
            box-shadow: 0 5px 12px -6px var(--c-neutral-100), 5px 5px 15px 5px rgba(0, 0, 0, 0);
        }
    }
}

.app-version {
    font-size: var(--f-size-xs);
    font-family: var(--f-monospace);
    text-align: center;
    color: var(--c-purple-300);

    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
}
