.container {
    position: relative;
    width: 100%;
    height: 6rem;
    padding: 1rem;
    z-index: 1;
    box-sizing: border-box;

    label {
        font-size: var(--f-size-md);
        user-select: none;
        position: absolute;
        top: 1.8rem;
        left: 1rem;
        z-index: -1;

        transition: font-size 200ms ease, top 200ms ease, color 200ms ease;
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 3rem 1rem 1rem 1rem;
        box-sizing: border-box;
        background: transparent;
        border: 1px solid var(--c-neutral-100);
        border-radius: 1rem;
        font-size: var(--f-size-md);

        transition: border 200ms ease, color 200ms ease;

        &::placeholder {
            opacity: 0;
        }

        &:focus {
            outline: 0.4rem solid var(--c-red-200-a);
            border: 1px solid var(--c-white);
        }

        &:-webkit-autofill,
        &:-webkit-autofill:focus {
            transition: background-color 600000s 0s, color 600000s 0s;
        }

        &:disabled {
            border-color: var(--c-background);
        }
    }

    input:focus ~ label,
    .hasContent {
        font-size: var(--f-size-sm);
        top: 0.4rem;
    }

    &.error {
        label {
            color: var(--c-red-200);
        }

        input {
            border-color: var(--c-red-200);
        }

        input:focus ~ label,
        input:not(:placeholder-shown) ~ label {
            color: var(--c-red-200);
        }
    }

    &.dark {
        label {
            color: var(--c-neutral-100);
        }

        input {
            color: var(--c-white);
        }

        input:focus ~ label,
        .hasContent {
            color: var(--c-white);
        }
    }

    &.light {
        label {
            color: var(--c-royal-blue-600);
        }

        input {
            color: var(--c-royal-blue-800);
        }

        input:focus ~ label,
        .hasContent {
            // color: var(--c-royal-blue-800);
            font-size: var(--f-size-sm);
            top: 0.4rem;
        }
    }
}
