.list {
    padding: 8.2rem 0;

    &.search {
        margin-top: -4rem;
        padding: 0;
    }

    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
    }
}
