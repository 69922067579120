.intro {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0;
    margin-top: -6rem;

    .preTitle {
        font-size: var(--f-size-xs);
        color: var(--c-blue-200);
        font-weight: var(--f-semi-bold);
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: 0.05rem;
    }

    h1 {
        font-size: var(--f-size-2xl);
        font-weight: var(--f-semi-bold);
        text-align: center;
    }
}

.search {
    padding: 8rem 0;

    .input {
        border-color: var(--c-blue-200);

        &:focus {
            border-color: var(--c-white);
        }
    }
}

.highlights {
    .carousel {
        margin-top: 3.2rem;
    }
}

.list {
    padding: 8.2rem 0;

    &.search {
        margin-top: -4rem;
        padding: 0;
    }

    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    .tagContainer {
        width: 100%;
        overflow: scroll;
        padding-bottom: 2rem;
        margin-bottom: 3.2rem;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 0.4rem;
    }
}
