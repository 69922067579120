.intro {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0;
    margin-top: -6rem;

    .preTitle {
        font-size: var(--f-size-xs);
        color: var(--c-blue-200);
        font-weight: var(--f-semi-bold);
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: 0.05rem;
    }

    h1 {
        font-size: var(--f-size-2xl);
        font-weight: var(--f-semi-bold);
    }
}

.search {
    margin-top: 5.6rem;

    p {
        font-size: var(--f-size-sm);
        text-align: center;
        margin-bottom: 2.4rem;
    }

    .input {
        border-color: var(--c-blue-200);
    }
}

.highlights {
    margin-top: 5.6rem;

    h2 {
        margin-bottom: 2.4rem;
    }
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 21rem;
    border-radius: 1rem;
    font-size: var(--f-size-lg);
    font-weight: var(--f-light);
    letter-spacing: 0.1rem;

    &.dark {
        background: var(--c-bluish-purple-800);
        color: var(--c-blue-200);
    }

    &.light {
        background: var(--c-blue-200);
        color: var(--c-white);
    }
}

.categories {
    margin-top: 8.2rem;

    .category {
        margin-bottom: 6rem;

        .label {
            text-transform: uppercase;
            font-size: var(--f-size-lg);
            line-height: 0;
            padding: 1.6rem 2.4rem;
            display: inline-block;
            border-radius: 1rem;
            margin-bottom: 2rem;

            &.dark {
                color: var(--c-blue-200);
                border: 1px solid var(--c-blue-200);
            }

            &.light {
                color: var(--c-blue-400);
                border: 1px solid var(--c-blue-400);
            }
        }
    }
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 4.2rem;
}

.list {
    margin-top: 8.2rem;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
}
