.effect {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background: var(--c-purple-600);

    span {
        position: absolute;
        width: 80vmin;
        height: 80vmin;
        border-radius: 50%;
        backface-visibility: hidden;

        z-index: -1;

        animation-name: move;
        animation-timing-function: linear;
        animation-iteration-count: infinite;

        &:nth-child(4),
        &:nth-child(6),
        &:nth-child(14) {
            animation-duration: 24s;
        }

        &:nth-child(2),
        &:nth-child(8),
        &:nth-child(5) {
            animation-duration: 28s;
        }

        &:nth-child(1),
        &:nth-child(7),
        &:nth-child(10),
        &:nth-child(12),
        &:nth-child(15) {
            animation-duration: 32s;
        }

        &:nth-child(3),
        &:nth-child(9),
        &:nth-child(11),
        &:nth-child(13) {
            animation-duration: 36s;
        }

        &:nth-child(1),
        &:nth-child(5),
        &:nth-child(10) {
            color: var(--c-purple-600);
        }

        &:nth-child(7),
        &:nth-child(11) {
            color: var(--c-bluish-purple-900);
        }

        &:nth-child(2),
        &:nth-child(8),
        &:nth-child(15) {
            color: var(--c-blue-400);
        }

        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(12),
        &:nth-child(14) {
            color: var(--c-magenta-500);
        }

        &:nth-child(4),
        &:nth-child(9),
        &:nth-child(13) {
            color: var(--c-red-200);
        }

        &:nth-child(1) {
            top: 64%;
            left: 88%;
            animation-delay: -10.8s;
            transform-origin: 10vw 8vh;
            box-shadow: 100vmin 0 21.7983332236vmin currentColor;
        }

        &:nth-child(2) {
            top: 91%;
            left: 50%;
            animation-delay: -5.2s;
            transform-origin: 23vw 10vh;
            box-shadow: 100vmin 0 16.2014342546vmin currentColor;
        }

        &:nth-child(3) {
            top: 48%;
            left: 6%;
            animation-delay: -10.7s;
            transform-origin: -9vw 10vh;
            box-shadow: 100vmin 0 28.4026146939vmin currentColor;
        }

        &:nth-child(4) {
            top: 6%;
            left: 38%;
            animation-delay: -10.4s;
            transform-origin: 21vw 9vh;
            box-shadow: 100vmin 0 19.813685953vmin currentColor;
        }

        &:nth-child(5) {
            top: 96%;
            left: 54%;
            animation-delay: -3.8s;
            transform-origin: 8vw 11vh;
            box-shadow: 80vmin 0 14.3701389872vmin currentColor;
        }

        &:nth-child(6) {
            top: 94%;
            left: 48%;
            animation-delay: -8.8s;
            transform-origin: 0vw 23vh;
            box-shadow: -80vmin 0 26.1020786239vmin currentColor;
        }

        &:nth-child(7) {
            top: 6%;
            left: 88%;
            animation-delay: -10.4s;
            transform-origin: -20vw -14vh;
            box-shadow: -80vmin 0 22.6802504349vmin currentColor;
        }

        &:nth-child(8) {
            top: 34%;
            left: 55%;
            animation-delay: -2.3s;
            transform-origin: -4vw 3vh;
            box-shadow: -80vmin 0 23.0535561996vmin currentColor;
        }

        &:nth-child(9) {
            top: 29%;
            left: 63%;
            animation-delay: -11.1s;
            transform-origin: -2vw 7vh;
            box-shadow: 80vmin 0 25.9772161378vmin currentColor;
        }

        &:nth-child(10) {
            top: 1%;
            left: 23%;
            animation-delay: -8.3s;
            transform-origin: -8vw 22vh;
            box-shadow: 80vmin 0 29.9380499608vmin currentColor;
        }

        &:nth-child(11) {
            top: 90%;
            left: 31%;
            animation-delay: -11.3s;
            transform-origin: -24vw -17vh;
            box-shadow: 80vmin 0 10.8600184349vmin currentColor;
        }

        &:nth-child(12) {
            top: 59%;
            left: 91%;
            animation-delay: -10.3s;
            transform-origin: -1vw -1vh;
            box-shadow: -80vmin 0 29.9083542719vmin currentColor;
        }

        &:nth-child(13) {
            top: 59%;
            left: 31%;
            animation-delay: -4.1s;
            transform-origin: -4vw 8vh;
            box-shadow: 80vmin 0 11.9246431503vmin currentColor;
        }

        &:nth-child(14) {
            top: 10%;
            left: 83%;
            animation-delay: -15.7s;
            transform-origin: -6vw 0vh;
            box-shadow: 80vmin 0 19.8643455254vmin currentColor;
        }

        &:nth-child(15) {
            top: 16%;
            left: 39%;
            animation-delay: -7.5s;
            transform-origin: -12vw -6vh;
            box-shadow: 80vmin 0 19.9497702734vmin currentColor;
        }
    }
}

@keyframes move {
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
}
