.memberCard {
    padding: 4rem 0 8rem;
    h2 {
        text-align: center;
        margin-bottom: 3rem;
    }

    &.dark {
        h2 {
            color: var(--c-green-200);
        }
    }

    &.light {
        h2 {
            color: var(--c-green-400);
        }
    }
}
