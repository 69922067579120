.current {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    .graph {
        transform: scale(0.8);
    }

    .trackers {
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        gap: 0.6rem;
        width: 100%;
        overflow: hidden;
    }
}
