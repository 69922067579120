.textarea-holder {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    row-gap: 0.6rem;

    textarea {
        border-radius: 1rem;
        // height: 12rem;
        padding: 1rem;
        resize: none;

        transition: border-color 200ms ease-in-out;
    }

    .word-counter {
        font-size: var(--f-size-xs);
        align-self: flex-end;
    }

    &.dark {
        textarea {
            background-color: var(--c-purple-700);
            border: 1px solid var(--c-purple-400);

            &:focus,
            &:focus-visible {
                border-color: var(--c-white);
                outline: none;
            }
        }

        .word-counter {
            color: var(--c-purple-100);

            &.alert {
                color: var(--c-red-300);
            }
        }
    }

    &.light {
        textarea {
            background-color: var(--c-white);
            border: 1px solid var(--c-neutral-100);

            &:focus,
            &:focus-visible {
                border-color: var(--c-purple-400);
                outline: none;
            }
        }

        .word-counter {
            color: var(--c-purple-500);

            &.alert {
                color: var(--c-red-300);
            }
        }
    }
}
