.card {
    width: 100%;
    max-width: 20rem;
    aspect-ratio: 3/4;
    cursor: pointer;
    background-size: cover;
    background-position: left center;
    background-repeat: no-repeat;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.4rem;

    border: none;
    border-radius: 1rem;
    padding: 1.6rem;

    transition: background-position 2200ms ease;

    & > * {
        text-align: left;
        color: var(--c-white);
    }

    .area {
        font-size: var(--f-size-xs);
        text-transform: uppercase;
        letter-spacing: 0.05rem;
    }
    .title {
        font-size: var(--f-size-md);
        font-weight: var(--f-semi-bold);
    }

    &:hover {
        background-position: right center;
    }

    &.dark {
        .area,
        .title {
            color: var(--c-neutral-800);
        }
    }

    &:focus {
        outline: 4px solid var(--c-red-200);
    }
}
