.container {
    width: 100%;
    position: relative;
    background: var(--c-background);

    transition: top 300ms ease-in-out, margin-bottom 300ms ease-in-out;

    input {
        position: relative;
        width: 100%;
        height: 4rem;
        border: none;
        border-radius: 2rem;
        border: 1px solid var(--c-white);
        padding: 1rem 3rem;
        background: transparent;
        color: var(--c-white);
        -webkit-appearance: none;

        transition: border 300ms ease;

        &:focus {
            outline: 0.4rem solid var(--c-red-200-a);

            & + svg path {
                fill: var(--c-red-200);
            }
        }

        &::placeholder {
            color: var(--c-neutral-100);
        }

        &::-webkit-search-cancel-button {
            // Using the two lines below will allow you to insert a image
            -webkit-appearance: none;
            -webkit-user-modify: read-write !important;
            content: '';
        }
    }

    svg {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;

        path {
            fill: var(--c-white);
            transition: fill 300ms ease;
        }
    }

    &::after {
        content: '';
        pointer-events: none;
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        width: calc(100% - 2px);

        background-image: linear-gradient(
            to left,
            var(--c-bluish-purple-900) 0%,
            rgba(30, 14, 48, 0) 40%
        );
        border-radius: 2rem;
    }

    .close-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1px;
        z-index: 1;

        background: transparent;
        border: none;

        svg {
            width: 1.4rem;
            height: 1.4rem;
        }
    }

    &.top {
        top: calc(12rem + var(--safe-area-inset-top));
        margin-bottom: calc(12rem + var(--safe-area-inset-top));
    }

    &.light {
        input {
            background: var(--c-white);
            color: var(--c-royal-blue-800);

            &::placeholder {
                color: var(--c-royal-blue-600);
            }
        }

        &::after {
            background-image: linear-gradient(
                to left,
                var(--c-white) 0%,
                rgba(255, 255, 255, 0) 40%
            );
        }

        svg path {
            fill: var(--c-royal-blue-600);
        }
    }
}
