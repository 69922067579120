.documents {
    padding: 8.2rem 0;

    h2 {
        text-align: center;
        margin-bottom: 4.8rem;
        color: var(--c-green-200);
    }

    .btn-group {
        margin-bottom: 6.3rem;
    }

    .doc {
        display: grid;
        grid-template-columns: 2.8rem auto;
        gap: 1rem;
        padding: 2.4rem 0;

        h3 {
            margin-bottom: 1rem;
            letter-spacing: 0.05rem;
        }

        .specs {
            font-size: var(--f-size-xs);
            text-transform: uppercase;
            letter-spacing: 0.05rem;
        }
    }

    &.dark {
        h2 {
            color: var(--c-green-200);
        }

        .doc {
            border-top: 1px solid var(--c-green-200);

            .icon {
                fill: var(--c-green-200);
            }

            h3 {
                color: var(--c-green-200);
            }

            .specs {
                color: var(--c-green-200);
            }

            &:last-of-type {
                border-bottom: 1px solid var(--c-green-200);
            }
        }
    }

    &.light {
        h2 {
            color: var(--c-green-400);
        }

        .doc {
            border-top: 1px solid var(--c-green-400);

            .icon {
                fill: var(--c-green-400);
            }

            h3 {
                color: var(--c-green-400);
            }

            .specs {
                color: var(--c-green-400);
            }

            &:last-of-type {
                border-bottom: 1px solid var(--c-green-400);
            }
        }
    }
}

.not-found-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0.8rem;

    padding: 3.6rem 1.4rem;
    border-radius: 1rem;

    transition: filter 300ms ease-in-out;

    .icon {
        width: 6rem;
        height: 6rem;

        path {
            stroke-width: 0.1rem;
        }
    }

    span {
        font-size: var(--f-size-sm);
        text-align: center;
    }

    &.dark {
        background: var(--c-bluish-purple-800);
        border: 1px solid var(--c-purple-700);

        .icon {
            stroke: var(--c-purple-200);
        }

        span {
            color: var(--c-purple-200);
        }
    }

    &.light {
        background: var(--c-white);
        border: 1px solid var(--c-neutral-100);

        .icon {
            stroke: var(--c-purple-400);
        }

        span {
            color: var(--c-purple-400);
        }
    }
}