.slider {
    &>span {
        position: absolute;
        top: 2rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
    }

    .logo {
        fill: var(--c-white);
        margin-top: calc(var(--safe-area-inset-top) + 12.4rem);

        transition: margin-top 600ms ease, width 600ms ease, height 600ms ease;

        &.home {
            margin-top: calc(10rem + var(--safe-area-inset-top));
            transform: scale(1.5);
        }

        @media screen and (min-width: 360px) {
            margin-top: calc(var(--safe-area-inset-top) + 14rem);
        }

        @media screen and (min-width: 375px) {
            margin-top: calc(var(--safe-area-inset-top) + 15rem);
        }

        @media screen and (min-width: 411px) {
            margin-top: calc(var(--safe-area-inset-top) + 17rem);
        }
    }

    .slide {
        width: 100%;
        height: 100vh;

        .slideOne {
            width: 100%;
            height: 100%;
            margin-top: 8rem;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 2.4rem;

            h1 {
                font-size: var(--f-size-2xl);
            }

            h1,
            p {
                color: var(--c-white);

                a {
                    font-weight: var(--f-semi-bold);
                }
            }
        }

        .slideTwo,
        .slideThree,
        .slideFour,
        .slideFive,
        .slideSix {
            width: 100%;
            height: 100%;
            padding: 1.6rem;
            background: var(--c-bluish-purple-900);

            .coverImage {
                height: 60vh;
            }

            .content {
                padding: 2rem 2rem 4rem;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                row-gap: 1.4rem;

                h2,
                p {
                    text-align: center;
                }

                h2 {
                    color: var(--c-white);
                    font-size: var(--f-size-md);
                }

                p {
                    color: var(--c-neutral-100);
                    font-weight: var(--f-light);
                    font-size: var(--f-size-sm);
                    line-height: 1.4;
                }
            }
        }
    }

    // @see: https://stackoverflow.com/questions/68094609/ios-15-safari-floating-address-bar
    .pagination {
        // bottom: calc(4rem + 10 * env(safe-area-inset-bottom));
        // top: calc(100 * var(--v-height) - 6rem);
        bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .bullet {
            display: inline-block;
            width: 0.4rem;
            height: 0.4rem;
            background: var(--c-white);
            border-radius: 50%;
            opacity: 1;
            opacity: 0.4;
            transform: scale(1);

            transition: transform 600ms ease, background-color 600ms ease;

            &.bulletActive {
                transform: scale(2);
                opacity: 1;
            }

            &+.bullet {
                margin-left: 1.6rem;
            }

            &:focus {
                outline: 2px solid var(--c-red-200);
            }
        }
    }

    .lang-button {
        position: absolute;
        top: calc(2rem + var(--safe-area-inset-top));
        right: 2rem;
        z-index: 1;
    }
}