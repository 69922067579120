.container {
    position: relative;
    width: 100%;
    height: 14rem;
    padding: 1rem;
    z-index: 1;
    box-sizing: border-box;

    label {
        font-size: var(--f-size-lg);
        user-select: none;
        pointer-events: none;
        position: absolute;
        top: 1.2rem;
        left: 1rem;
        z-index: 2;

        transition: font-size 200ms ease, top 200ms ease, color 200ms ease;
    }

    textarea {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 3rem 5rem 1rem 1rem;
        box-sizing: border-box;
        background: transparent;
        border: 1px solid var(--c-neutral-100);
        border-radius: 1rem;
        font-size: var(--f-size-md);
        resize: none;

        transition: border 200ms ease, color 200ms ease;

        &::placeholder {
            opacity: 0;
        }

        &:focus {
            border: 1px solid var(--c-white);
            outline: 0.4rem solid var(--c-red-200-a);
        }
    }

    textarea:focus ~ label,
    textarea:not(:placeholder-shown) ~ label {
        font-size: var(--f-size-sm);
        top: 0.4rem;
    }

    &.error {
        label {
            color: var(--c-red-200);
        }

        textarea {
            border-color: var(--c-red-200);
        }

        textarea:focus ~ label,
        textarea:not(:placeholder-shown) ~ label {
            color: var(--c-red-200);
            font-size: var(--f-size-sm);
            top: 0.4rem;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        height: 6rem;
        border-radius: 1rem;

        user-select: none;
        pointer-events: none;
        z-index: 1;
    }

    &.light {
        label {
            color: var(--c-royal-blue-600);
        }

        textarea {
            color: var(--c-neutral-800);
        }

        textarea:focus ~ label,
        textarea:not(:placeholder-shown) ~ label {
            color: var(--c-royal-blue-600);
        }

        &::before {
            background-image: linear-gradient(to bottom, var(--c-white), rgba(255, 255, 255, 0));
        }
    }

    &.dark {
        label {
            color: var(--c-neutral-100);
        }

        textarea {
            color: var(--c-white);
        }

        textarea:focus ~ label,
        textarea:not(:placeholder-shown) ~ label {
            color: var(--c-white);
        }

        &::before {
            background-image: linear-gradient(
                to bottom,
                var(--c-bluish-purple-900),
                rgba(30, 14, 48, 0)
            );
        }
    }
}
