.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    strong {
        color: var(--c-blue-500);
        font-size: var(--f-size-2xl);
        line-height: 1;
        text-transform: uppercase;
        text-align: center;
    }

    .button {
        margin-top: 4.2rem;
    }
}
