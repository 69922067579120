.linkBlock {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.8rem;
    box-sizing: border-box;

    width: 100%;
    padding: 0.4rem;
    color: var(--c-white);
    font-family: var(--f-main);
    font-size: var(--f-size-md);
    font-weight: var(--f-light);
    text-decoration: none;
    background: inherit;

    transition: filter 300ms ease;

    span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.8rem;

        svg {
            width: 1.6rem;
            height: 1.6rem;

            path {
                fill: var(--c-white);
            }
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--c-white);
        opacity: 0;
        z-index: -1;
        pointer-events: none;

        transition: opacity 200ms ease;
    }

    &:hover::after,
    &:focus::after {
        opacity: 0.1;
    }

    &:focus {
        outline: none;
    }

    &.md {
        font-size: var(--f-size-lg);
        padding: 0.8rem;
    }
}

.link {
    position: relative;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.8rem;

    color: var(--c-white);
    font-family: var(--f-main);
    font-size: var(--f-size-md);
    font-weight: var(--f-semi-bold);
    letter-spacing: 0.05px;
    padding: 0.4rem 0;
    text-decoration: none;
    overflow: hidden;

    span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.8rem;

        svg {
            width: 1.6rem;
            height: 1.6rem;

            path {
                fill: var(--c-white);
            }
        }
    }

    &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        opacity: 0;
        background: var(--c-white);

        transition: bottom 300ms ease, opacity 300ms ease;
    }

    &:hover {
        &::after {
            bottom: 0.4rem;
            opacity: 1;
        }
    }

    &:focus {
        outline: 0.4rem solid var(--c-red-200);
        outline-offset: 0.4rem;
    }

    &.md {
        font-size: var(--f-size-lg);
        padding: 0.8rem 0;

        svg {
            width: 2rem;
            height: 2rem;
        }
    }
}

.button {
    position: relative;
    color: var(--c-purple-600);
    font-family: var(--f-main);
    font-weight: var(--f-semi-bold);
    line-height: 1;
    letter-spacing: 0.04rem;
    text-decoration: none;
    cursor: pointer;

    background: var(--c-white);
    border: none;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;

    overflow: hidden;
    z-index: 1;

    transition: transform 200ms ease-in-out;

    height: 3.6rem;
    padding: 0 1.6rem;
    font-size: var(--f-size-sm);
    border-radius: 1rem;

    span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.8rem;

        svg {
            width: 1.6rem;
            height: 1.6rem;

            path {
                fill: var(--c-purple-600);
            }
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        width: 200%;
        height: 200%;
        background: var(--c-purple-600);
        opacity: 0.1;
        border-radius: 50%;
        filter: blur(5px);
        z-index: -1;
        transform: translateX(-50%);

        transition: top 300ms ease;
    }

    &:hover {
        transform: scale(1.01);

        &::after {
            top: -200%;
        }
    }

    &:focus {
        outline: 0.4rem solid var(--c-red-200);
    }

    &.full {
        display: flex;
    }

    &.md {
        height: 5.2rem;
        padding: 0 3.6rem;
        font-size: var(--f-size-md);
        border-radius: 1.6rem;
    }

    &.nutrition,
    &.movement,
    &.health {
        color: var(--c-white);

        svg path {
            fill: var(--c-white);
        }

        &::after {
            background: var(--c-white);
        }
    }

    &.nutrition {
        background: var(--c-magenta-500);
    }

    &.movement {
        background: var(--c-blue-400);
    }

    &.health {
        background: var(--c-purple-600);
    }
}

.reverse>span {
    flex-direction: row-reverse;
}

.disabled {
    opacity: 0.3;
    pointer-events: none;

    &:focus {
        outline: none;
    }
}