.deliveries {
    margin-top: 9rem;

    h2 {
        margin-bottom: 4.8rem;
    }

    ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        list-style-type: none;
        gap: 2.4rem;

        li {
            button {
                height: 8.2rem;

                display: flex;
                align-items: center;
                justify-content: center;

                border-radius: 1rem;
                border: none;
                background: transparent;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}