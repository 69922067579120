.container {
    background: var(--c-white);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .closeButton {
        position: absolute;
        top: var(--safe-area-inset-top);
        right: 0;
        z-index: 2;
    }

    .slider {
        background: var(--c-white);
        height: 100vh;

        .slide {
            display: grid;
            grid-template-rows: 60vh auto;
            background: var(--c-white);

            .wrapper {
                height: 100%;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                padding: 1.6rem;

                h2,
                p {
                    text-align: center;
                }

                h2 {
                    color: var(--c-purple-600);
                    font-weight: var(--semi-bold);
                    margin-bottom: 0.8rem;
                }

                p {
                    color: var(--c-royal-blue-600);

                    span {
                        color: var(--c-purple-600);
                    }
                }

                .button {
                    border: 1px solid var(--c-royal-blue-600);
                    margin-top: 1.6rem;
                }
            }
        }

        & > div:first-of-type {
            height: 2rem;

            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;

            .bullet {
                width: 0.4rem;
                height: 0.4rem;
                border-radius: 50%;
                background: var(--c-purple-600);
                opacity: 0.4;

                &.bulletActive {
                    transform: scale(2);
                    opacity: 1;
                }
            }
        }

        & > span {
            position: absolute;
            top: 2rem;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;

            .logo {
                margin-top: calc(var(--safe-area-inset-top) + 2rem);
            }
        }
    }
}
