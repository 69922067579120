.locations {
    .region {
        &+.region {
            margin-top: 6rem;
        }

        &>p {
            color: var(--c-magenta-500) !important;
            margin-bottom: 3rem;
        }

        .card-location {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            gap: 1rem;

            padding: 1.6rem;
            border-radius: 1rem;

            .wrapper {
                width: calc(100% - 4rem);
                display: flex;
                justify-content: center;
                flex-direction: column;

                strong {
                    margin-bottom: 1rem;
                }

                span {
                    font-size: var(--f-size-sm);
                    line-height: 1.8rem;
                }
            }

            .button-maps {
                background: transparent;
                border: none;
                width: 2.6rem;
                height: 2.6rem;
                display: block;
            }

            &+.card-location {
                margin-top: 1.6rem;
            }

            &.dark {
                background: var(--c-bluish-purple-800);
                box-shadow: 0 10px 13px -7px rgba(0, 0, 0, 0.2), 5px 5px 15px 5px rgba(0, 0, 0, 0);

                .wrapper {
                    strong {
                        color: var(--c-bluish-purple-100);
                    }

                    span {
                        color: var(--c-bluish-purple-200);
                    }
                }
            }

            &.light {
                background: var(--c-white);
                box-shadow: 0 10px 13px -7px rgba(0, 0, 0, 0.02), 5px 5px 15px 5px rgba(0, 0, 0, 0);

                .wrapper {
                    strong {
                        color: var(--c-royal-blue-600);
                    }

                    span {
                        color: var(--c-royal-blue-300);
                    }
                }
            }
        }
    }

    .button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 1.6rem;

        margin-top: 1.6rem;
        border-radius: 1rem;
        padding: 3.2rem;

        .button {
            position: relative;

            width: 100%;
            height: 3.6rem;
            padding: 0 1.6rem;

            background: var(--c-magenta-500);
            color: var(--c-white);
            font-family: var(--f-main);
            font-size: var(--f-size-sm);
            font-weight: var(--f-semi-bold);
            line-height: 1;
            letter-spacing: 0.04rem;

            display: flex;
            align-items: center;
            justify-content: center;

            cursor: pointer;
            border-radius: 1rem;
            border: none;

            transition: transform 200ms ease-in-out;
        }

        .external-warning {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                width: calc(100% - 6rem);
                font-size: var(--f-size-xs);
                font-weight: var(--f-light);
                letter-spacing: 0.02rem;
                line-height: 1.6rem;
                margin: 0;
                flex: 1;
            }

            .icon {
                width: 2rem;
                fill: var(--c-magenta-500) !important;
            }
        }

        &.dark {
            background: var(--c-bluish-purple-800);

            .external-warning {
                p {
                    color: var(--c-magenta-200);
                }

                .icon {
                    fill: var(--c-magenta-200) !important;
                }
            }
        }

        &.light {
            background: var(--c-white);

            .external-warning {
                p {
                    color: var(--c-magenta-500);
                }

                .icon {
                    fill: var(--c-magenta-500) !important;
                }
            }
        }
    }

}