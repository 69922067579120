* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

*::before,
*::after {
    box-sizing: border-box;
}

html {
    height: 100%;
}

img {
    display: block;
    max-width: 100%;
    user-select: none;
    -webkit-user-select: none;
    -webkit-user-drag: none;
    -webkit-app-region: no-drag;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
li,
figure,
video {
    margin: 0;
    padding: 0;
}

button {
    cursor: pointer;
}

a {
    color: inherit;
    text-decoration: none;
}
