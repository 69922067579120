.container {
    position: relative;
    width: 100%;
    height: 6rem;
    padding: 1rem;
    z-index: 1;
    box-sizing: border-box;

    label {
        font-size: var(--f-size-md);
        user-select: none;
        position: absolute;
        top: 1.8rem;
        left: 1rem;
        z-index: -1;

        transition: font-size 200ms ease, top 200ms ease, color 200ms ease;
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 3rem 1rem 1rem 1rem;
        box-sizing: border-box;
        background: transparent;
        border: 1px solid var(--c-neutral-100);
        border-radius: 1rem;
        font-size: var(--f-size-md);
        color: var(--c-element);

        transition: border 200ms ease, color 200ms ease;

        &::placeholder {
            opacity: 0;
        }

        &:focus {
            outline: 0.4rem solid var(--c-red-200-a);
            border: 1px solid var(--c-white);
        }

        &:-webkit-autofill,
        &:-webkit-autofill:focus {
            transition: background-color 600000s 0s, color 600000s 0s;
        }
    }

    input:focus ~ label,
    input:not(:placeholder-shown) ~ label {
        font-size: var(--f-size-sm);
        top: 0.4rem;
    }

    &.dark {
        label {
            color: var(--c-neutral-100);
        }

        input {
            color: var(--c-white);
            border: 1px solid var(--c-neutral-100);

            &:focus ~ label,
            &:not(:placeholder-shown) ~ label {
                color: var(--c-white);
            }
        }
    }

    &.light {
        label {
            color: var(--c-royal-blue-600);
        }

        input {
            color: var(--c-royal-blue-800);
            border: 1px solid var(--c-neutral-100);

            &:focus ~ label,
            &:not(:placeholder-shown) ~ label {
                color: var(--c-royal-blue-600);
            }
        }
    }

    .toggle-button {
        position: absolute;
        top: 50%;
        right: 1rem;
        width: 3.6rem;
        height: 3.6rem;
        transform: translateY(-50%);
        background: transparent;
        border: none;
        border-radius: 50%;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;

        path {
            fill: var(--c-purple-300);
        }
    }

    &.error {
        label {
            color: var(--c-red-200);
        }

        input {
            border-color: var(--c-red-200);
        }

        input:focus ~ label,
        input:not(:placeholder-shown) ~ label {
            color: var(--c-red-200);
            font-size: var(--f-size-sm);
            top: 0.4rem;
        }

        .toggleButton {
            opacity: 0;
        }
    }
}
