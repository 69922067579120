.card {
    width: 100%;
    border: none;
    border-radius: 1rem;
    box-sizing: border-box;

    & > * {
        color: var(--c-white);
    }

    .icon {
        opacity: 0.5;
    }

    .wrapper {
        width: 100%;

        .label {
            font-weight: var(--f-semi-bold);
            font-size: var(--f-size-xs);
            text-transform: uppercase;
            letter-spacing: 0.05rem;
        }

        .percentage {
            display: flex;

            font-size: var(--f-size-2xl);
            font-weight: var(--f-light);
            line-height: 1;
            letter-spacing: 0.05rem;

            span:last-of-type {
                font-size: var(--f-size-lg);
                font-weight: var(--f-regular);
                margin-top: 0.2rem;
            }
        }
    }

    .button {
        opacity: 0.8;
        font-size: var(--f-size-xs);
        font-weight: var(--f-regular);
        padding: 0 1rem;
    }

    &.vertical {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 0.8rem;
        padding: 0.8rem;

        .wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &.health {
            background-image: var(--g-gradient-health-primary);
        }

        &.movement {
            background-image: var(--g-gradient-movement-primary);
        }

        &.nutrition {
            background-image: var(--g-gradient-nutrition-primary);
        }
    }

    &.horizontal {
        display: grid;
        grid-template-columns: 1fr 3fr 2fr;
        align-items: center;
        gap: 0.8rem;
        padding: 0.8rem 1.6rem;

        &.health {
            background-image: var(--g-gradient-health-primary-alt);
        }

        &.movement {
            background-image: var(--g-gradient-movement-primary-alt);
        }

        &.nutrition {
            background-image: var(--g-gradient-nutrition-primary-alt);
        }
    }

    &.health {
        .button {
            color: var(--c-purple-600);
        }
    }

    &.movement {
        .button {
            color: var(--c-blue-400);
        }
    }

    &.nutrition {
        .button {
            color: var(--c-magenta-500);
        }
    }

    &.health,
    &.movement,
    &.nutrition {
        background-size: 100%;
        background-position: center;

        transition: background-size 1000ms ease;

        &:hover {
            background-size: 300%;
        }
    }

    &:focus {
        outline: 0.4rem solid var(--c-red-200);
    }
}
