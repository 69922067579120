.card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 4rem;
    gap: 1rem 0;
    grid-template-areas:
        'info info info'
        'points link link';

    padding: 1.6rem;
    border-radius: 1rem;

    .image {
        grid-area: image;
        width: 8.6rem;
        aspect-ratio: 1;
        object-fit: cover;
        border-radius: 1rem;
    }

    .info {
        grid-area: info;

        .title,
        .description {
            font-size: var(--f-size-sm);
            letter-spacing: 0.05rem;
        }

        .title {
            font-weight: var(--f-bold);
            text-transform: uppercase;
            display: inline-block;
            line-height: 1.2;
            margin-top: 0.6rem;
        }

        .description {
            font-weight: var(--f-light);
            line-height: 1.4;
            margin: 0;
        }
    }

    .points {
        grid-area: points;

        font-weight: var(--f-semi-bold);
        letter-spacing: 0.05rem;

        display: flex;
        justify-content: flex-start;
        align-items: center;

        .icon {
            margin-right: 1rem;
        }

        &.movement {
            color: var(--c-blue-200);

            .icon path {
                fill: var(--c-blue-200);
            }
        }

        &.nutrition {
            color: var(--c-magenta-300);

            .icon path {
                fill: var(--c-magenta-300);
            }
        }

        &.health {
            color: var(--c-purple-300);

            .icon path {
                fill: var(--c-purple-300);
            }
        }

        &.articles {
            color: var(--c-red-100);

            .icon path {
                fill: var(--c-red-100);
            }
        }
    }

    .link {
        grid-area: link;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    & + .card {
        margin-top: 3.2rem;
    }

    &.points {
        grid-template-areas:
            'info info info'
            'points link link';
    }

    &.image {
        grid-template-areas:
            'image info info'
            'link link link';

        &.points {
            grid-template-areas:
                'image info info'
                'points link link';
        }
    }

    &.dark {
        border: 1px solid var(--c-neutral-800);

        .info {
            .title {
                color: var(--c-white);
            }
            .description {
                color: var(--c-neutral-100);
            }
        }
    }

    &.light {
        border: 1px solid var(--c-neutral-100);
        background: var(--c-white);

        .info {
            .title {
                color: var(--c-royal-blue-800);
            }
            .description {
                color: var(--c-royal-blue-800);
            }
        }
    }
}
