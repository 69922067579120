.assessment {
    padding: 4.8rem 0;

    .card {
        background-image: var(--g-gradient-nutrition-secondary);
    }

    &.light {
        .card * {
            color: var(--c-white) !important;
        }
    }
}