.intro {
    h1 {
        font-size: var(--f-size-lg);
        font-weight: var(--f-semi-bold);
        margin-bottom: 1.2rem;
    }

    p {
        font-size: var(--f-size-sm);
        line-height: 2rem;
    }
}
