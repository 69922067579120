.main {
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    .icon {
        fill: var(--c-bluish-purple-400);
        border: 1px solid var(--c-bluish-purple-400);
        padding: 1.6rem;
        border-radius: 1.6rem;
        margin-bottom: 4.2rem;
    }

    & > * {
        text-align: center;
    }

    h1 {
        font-family: var(--f-monospace);
        font-weight: var(--f-light);
        font-size: var(--f-size-4xl);
    }

    p {
        line-height: 1.6;
    }

    .back-button {
        position: fixed;
        bottom: 2.4rem;
        left: 2.4rem;

        background-color: var(--c-bluish-purple-800);

        svg {
            fill: var(--c-bluish-purple-200);
        }
    }
}
