.section {
    h1 {
        margin-bottom: 3.2rem;
    }

    p {
        line-height: 1.6;

        & + p {
            margin-top: 1rem;
        }

        &.small {
            margin-top: 6.4rem;
            font-size: var(--f-size-xs);
        }
    }
}
