.card {
    text-decoration: none !important;
    color: var(--c-white);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 1rem;

    .image-container {
        width: 100%;
        height: 20rem;
        border-radius: 1rem;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &.dark {
            background: var(--c-bluish-purple-800);
        }

        &.light {
            background: var(--c-white);
        }
    }

    .info {
        padding: 1rem;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1rem;

        h3 {
            margin-top: 0;
        }

        .description {
            font-size: var(--f-size-sm);
        }

        .note {
            margin-top: 1rem;
            font-size: 1rem;
            text-transform: uppercase;
            letter-spacing: 0.1rem;
            color: var(--c-blue-200);
        }

        .label {
            width: 4.2rem;
            height: 1.6rem;

            display: flex;
            align-items: center;
            justify-content: center;

            background: var(--c-blue-400);
            font-size: 1rem;
            font-weight: var(--f-semi-bold);
            line-height: 0;
            letter-spacing: 0.1rem;
            text-transform: uppercase;
            border-radius: 1rem;
            padding: 0 0.4rem;
        }

        &.light {
            h3 {
                color: var(--c-royal-blue-800);
            }

            .description {
                color: var(--c-royal-blue-600);
            }

            .note {
                color: var(--c-blue-400);
            }
        }
    }

    &:focus {
        outline: none;
        background: var(--c-bluish-purple-800);
    }
}
