.card {
    position: relative;
    display: block;
    width: 100%;
    max-width: 33rem;
    min-height: 22rem;
    aspect-ratio: 1.618 / 1;
    margin: 0 auto;
    perspective: 60rem;
    cursor: pointer;

    border: none;
    border-radius: 1.2rem;
    background: transparent;
    text-align: left;

    filter: brightness(1);
    transition: filter 300ms ease;

    .cardFace {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 22rem;
        z-index: 9999;
        aspect-ratio: 1.618 / 1;

        border-radius: 1.2rem;
        background-size: cover;

        transition: transform 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transform-style: preserve-3d;
        backface-visibility: hidden;

        &.cardFront {
            transform: rotateY(0);
            box-sizing: border-box;
            padding: 1.6rem;
            display: grid;
            // grid-template-rows: 1fr 1fr 1fr;
            gap: 1rem;

            .header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .plan {
                    width: 10.5rem;
                    height: 3.6rem;
                    border-radius: 0.8rem;
                    background: rgba(255, 255, 255, 0.2);

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    font-weight: var(--f-semi-bold);
                    color: var(--c-white);
                    text-transform: uppercase;
                    letter-spacing: 0.05rem;
                }
            }

            .body {
                display: flex;
                align-items: center;
                justify-content: center;

                .number {
                    font-family: var(--f-monospace);
                    font-size: var(--f-size-xl);
                    color: var(--c-white);
                    letter-spacing: 0.5rem;
                }
            }

            .footer {
                display: block;

                .name,
                .date {
                    font-family: var(--f-monospace);
                    text-transform: uppercase;
                    color: var(--c-white);
                    display: block;
                    letter-spacing: 0.1rem;
                }
            }
        }

        &.cardBack {
            box-sizing: border-box;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: rotateY(-180deg);
        }
    }

    &.flipped {
        animation-play-state: running;
        .cardFront {
            transform: rotateY(180deg);
        }

        .cardBack {
            transform: rotateY(0);
        }
    }

    &.preview {
        .cardFront {
            transform: rotateY(10deg);
        }

        .cardBack {
            transform: rotateY(-170deg);
        }
    }

    &:focus {
        outline: none;
        filter: brightness(1.4);
    }
}
