.card {
    display: block;
    border-radius: 1.2rem;
    background: var(--c-background);

    transition: filter 600ms ease-in-out;

    .card-header {
        padding: 1rem 0;

        strong {
            font-size: var(--f-size-lg);
            font-weight: var(--f-semi-bold);
            line-height: 1;
        }
    }

    .card-image {
        position: relative;

        width: 100%;
        min-height: 30rem;
        aspect-ratio: 1;
        border-radius: 1.2rem;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            transition: transform 1000ms ease-in-out;
        }

        .invalid-message {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding: 1.6rem;

            span {
                color: var(--c-white);
                background: var(--c-magenta-500);
                line-height: 1;
                height: 3.6rem;
                padding: 0 3rem;
                text-align: center;
                border-radius: 1.8rem;

                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1rem;

                .icon {
                    fill: var(--c-white) !important;
                }
            }

            &.dark {
                background: rgba(0, 0, 0, 0.4);
            }

            &.light {
                background: rgba(255, 255, 255, 0.6);
            }
        }

        &.dark {
            box-shadow: 0 20px 13px -7px rgba(0, 0, 0, 0.2), 5px 5px 15px 5px rgba(0, 0, 0, 0);
        }

        &.light {
            filter: brightness(1.1);
            box-shadow: 0 20px 13px -7px rgba(0, 0, 0, 0.1), 5px 5px 15px 5px rgba(0, 0, 0, 0);
        }
    }

    .card-body {
        padding: 2.6rem 0 0 0;

        p {
            font-size: var(--f-size-md);
            line-height: 2.2rem;

            &+p {
                margin-top: 2.6rem;
            }

            &.notes {
                font-size: var(--f-size-xs);
                font-weight: var(--f-light);
                line-height: 1.8rem;
                letter-spacing: 0.01rem;

                &.dark {
                    color: var(--c-purple-50);
                }

                &.light {
                    color: var(--c-magenta-300);
                }
            }
        }
    }

    &.invalid {
        cursor: not-allowed;
    }

    &:hover,
    &:focus,
    &:focus-visible {
        filter: brightness(1.1);
        outline: none;

        .card-image {
            img {
                transform: scale(1.1);
            }
        }
    }
}