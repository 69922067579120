.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1.8rem;

  padding: 2.4rem 1.4rem;

  border-radius: 1rem;

  transition: filter 300ms ease-in-out;

  &+.card {
    margin-top: 2.6rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0.8rem;


    &>* {
      text-align: center;
    }

    .date {
      height: 1.5rem;
      font-size: var(--f-size-xs);
      text-transform: uppercase;
      line-height: 1;
    }

    .title {
      height: 1.9rem;
      font-size: var(--f-size-md);
      font-weight: var(--f-semi-bold);
      line-height: 1;
    }

    .description {
      font-size: var(--f-size-sm);
      line-height: 115%;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1.4rem;
  }

  .payment-details {
    align-self: stretch;
    margin: 0 3.75rem;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    row-gap: 1.2rem;

    .title {
      text-transform: uppercase;
      text-align: center;
      font-size: var(--f-size-sm);
      display: block;
    }

    ul {
      list-style-type: none;

      li {
        font-size: var(--f-size-sm);
        text-align: center;
        text-transform: uppercase;

        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  &.dark {
    background: var(--c-bluish-purple-800);

    &.payed {
      border: 1px solid var(--c-purple-700);
    }

    &.pending {
      border: 1px solid var(--c-magenta-700);
    }

    &.overdue {
      border: 1px solid var(--c-red-600);
    }

    .content {
      .date {
        color: var(--c-purple-200)
      }
    }

    .payment-details {
      .title {
        color: var(--c-purple-200);
      }

      ul li {
        span {
          &:first-of-type {
            color: var(--c-purple-200);
          }

          &:last-of-type {
            color: var(--c-white);
          }
        }

        &.alert span {
          color: var(--c-red-500);
          font-weight: var(--f-semi-bold);
        }
      }
    }

    &:hover {
      filter: brightness(1.1)
    }
  }

  &.light {
    background: var(--c-white);
    color: var(--c-purple-700);

    &.payed {
      border: 1px solid var(--c-neutral-100);
    }

    &.pending {
      border: 1px solid var(--c-magenta-200);
    }

    &.overdue {
      border: 1px solid var(--c-red-200);
    }

    .content {
      .date {
        color: var(--c-purple-400)
      }
    }

    .payment-details {
      .title {
        color: var(--c-purple-400)
      }

      ul li {
        span {
          &:first-of-type {
            color: var(--c-purple-400);
          }

          &:last-of-type {
            color: var(--c-purple-700);

          }
        }

        &.alert span {
          color: var(--c-red-500);
          font-weight: var(--f-semi-bold);
        }
      }
    }

    &:hover {
      filter: brightness(1.1)
    }
  }
}