.container {
    width: 94%;
    max-width: 32rem;
    height: calc(100 * var(--v-height) - 4rem);
    margin: 2rem auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    .logo {
        fill: var(--c-white);
        margin-top: var(--safe-area-inset-top);
    }

    h1 {
        font-size: var(--f-size-2xl);
        margin-bottom: 2.4rem;
        text-align: center;
    }

    p {
        text-align: center;
    }

    h1,
    p {
        color: var(--c-white);
    }

    .form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2.6rem;

        .input {
            input {
                color: var(--c-white);
            }

            label {
                color: var(--c-white) !important;
            }
        }
    }

    .register {
        margin-top: 6rem;
        font-size: var(--f-size-sm);

        & > a {
            font-size: var(--f-size-sm);
            font-weight: var(--f-semi-bold);
        }
    }

    .lang-button {
        position: absolute;
        top: calc(2rem + var(--safe-area-inset-top));
        right: 2rem;
        z-index: 1;
    }
}

.modal {
    background-color: var(--c-white);
    text-align: center !important;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    h2 {
        font-size: var(--f-size-lg);
    }

    p {
        color: var(--c-neutral-700);
        font-size: var(--f-size-sm);
        font-weight: var(--f-light);

        .link {
            font-weight: var(--f-semi-bold);
            text-decoration: underline;
        }
    }

    .button {
        margin: 4.2rem 0;
        transition: border 200ms ease-in-out, background-color 200ms ease-in-out,
            color 200ms ease-in-out;
    }

    &.error {
        h2 {
            color: var(--c-red-500);
        }

        .button {
            border: 1px solid var(--c-red-200);
            color: var(--c-red-500);

            &:hover {
                border-color: var(--c-red-500);
                background: var(--c-red-500);
                color: var(--c-white);
            }
        }
    }

    &.info {
        h2 {
            color: var(--c-magenta-500);
        }

        .button {
            border: 1px solid var(--c-magenta-200);
            color: var(--c-magenta-500);

            &:hover {
                border-color: var(--c-magenta-500);
                background: var(--c-magenta-500);
                color: var(--c-white);
            }
        }
    }

    &.success {
        h2 {
            color: var(--c-green-400);
        }

        .button {
            border: 1px solid var(--c-green-200);
            color: var(--c-green-400);

            &:hover {
                border-color: var(--c-green-400);
                background: var(--c-green-400);
                color: var(--c-white);
            }
        }
    }
}
