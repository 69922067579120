.card {
    width: 100%;
    border-radius: 1rem;

    .card-header {
        position: relative;
        background-image: var(--g-gradient-movement-secondary);
        display: flex;
        align-items: center;
        justify-content: center;

        height: 16rem;
        border-radius: 1rem;

        .button {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
        }
    }

    .card-body {
        color: var(--c-white);
        padding: 3.6rem 1.6rem;

        p,
        li {
            font-size: var(--f-size-sm);
            line-height: 1.4;
            margin: 0;

            & + p {
                margin-top: 1rem;
            }
        }

        ul {
            padding: 0.4rem 0 2rem 1.6rem;

            li + li {
                margin-top: 0.6rem;
            }
        }

        a {
            text-decoration: underline;
        }
    }

    &.dark {
        background: var(--c-bluish-purple-800);

        .card-body {
            color: var(--c-white);
        }
    }

    &.light {
        background: var(--c-white);

        .card-body {
            color: var(--c-royal-blue-600);
        }
    }

    &.movement .card-header {
        background-image: var(--g-gradient-movement-secondary);
    }

    &.health .card-header {
        background-image: var(--g-gradient-health-secondary);
    }

    &.nutrition .card-header {
        background-image: var(--g-gradient-nutrition-secondary);
    }
}
