.button {
  height: 4.8rem;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 0 2.4rem;
  border: none;
  border-radius: 1rem;

  transition: background-color 300ms ease-in-out;

  .icon {
    width: 3rem !important;
    height: 3.6rem !important;
    transition: stroke 300ms ease-in-out;

    path {
      stroke-width: 0.15rem;
    }
  }
}

.dark {
  &.button {
    background: var(--c-purple-700);

    .icon {
      stroke: var(--c-purple-100);
    }

    &:hover {
      background: var(--c-purple-500);

      .icon {
        stroke: var(--c-white);
      }
    }
  }
}

.light {
  &.button {
    background: var(--c-purple-50);

    .icon {
      stroke: var(--c-purple-400);
    }

    &:hover {
      background: var(--c-purple-500);
  
      .icon {
        stroke: var(--c-white);
      }
    }
  }
}