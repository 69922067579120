.modal-window {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 1000;
    cursor: pointer;
    border: none;
    opacity: 1;
    transition: opacity 0.3s;

    &.dark {
        background-color: rgba(0, 0, 0, 0.8);
        backdrop-filter: grayscale(100%) brightness(0.5);
    }

    &.light {
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: grayscale(100%) brightness(0.4);
    }

    &.overlay-dark {
        background-color: rgba(0, 0, 0, 0.8);
        backdrop-filter: grayscale(100%) brightness(0.5);
    }

    &.overlay-light {
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: grayscale(100%) brightness(0.4);
    }

    &.inactive {
        height: 0;
        opacity: 0;
        transition: height 0s 0.3s, opacity 0.3s;
    }

    .wrapper {
        padding: 4rem 0;
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal {
        z-index: 500;
        width: calc(100% - 4rem);
        max-width: 60rem;
        margin: 0 auto;
        padding: 2rem;
        box-sizing: border-box;
        border-radius: 2rem;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
        cursor: initial;
        opacity: 0;
        transform: translateY(-100%);
        transition: all 300ms ease-out;

        & * {
            box-sizing: border-box;
        }

        &.animate {
            transform: translateY(0);
            opacity: 1;
        }

        .modal-btn {
            display: inline-block;
            color: var(--c-white);
            font-size: 1.4rem;
            font-weight: var(--f-semi-bold);
            text-align: center;
            line-height: 1;
            vertical-align: middle;
            user-select: none;
            background-color: transparent;
            border: none;
            padding: 1rem 1.4rem;
            border-radius: 0.4rem;
            transition: filter 300ms ease;

            &:hover {
                cursor: pointer;
                filter: brightness(1.2);
            }

            &:disabled {
                cursor: not-allowed;
                opacity: 0.8;
            }

            & + .modal-btn {
                margin-left: 0.4rem;
            }

            &.btn-primary {
                color: var(--c-purple-600);
                background-color: var(--c-white);
            }

            &.btn-secondary {
                color: var(--c-white);
                background-color: var(--c-royal-blue-600);
            }

            &.btn-success {
                color: var(--c-bluish-purple-900);
                background-color: var(--c-green-400);
            }

            &.btn-danger {
                color: var(--c-white);
                background-color: var(--c-magenta-500);
            }

            &.btn-warning {
                color: var(--c-royal-blue-800);
                background-color: var(--c-yellow-200);
            }

            &.btn-info {
                color: var(--c-white);
                background-color: var(--c-blue-400);
            }

            &.btn-dark {
                color: var(--c-white);
                background-color: var(--c-royal-blue-800);
            }

            .btn-content {
                display: flex;
                align-items: center;
            }
        }

        .modal-header {
            position: relative;
            padding: 1rem;
            font-weight: var(--f-semi-bold);
            font-size: var(--f-size-lg);
            display: flex;
            flex-direction: column;

            & > div {
                display: flex;
                justify-content: space-between;
            }

            .modal-title {
                color: var(--c-white);
                margin: 0;
                line-height: 1.4;
                text-transform: capitalize;
            }

            .header-close-btn {
                cursor: pointer;
                padding: 0;
                border: 0;
                line-height: 0;
                outline: none;
                background: transparent;
            }

            p {
                margin: 0.4rem 0 0;
                font-size: var(--f-size-sm);
            }
        }

        .modal-body {
            position: relative;
            padding: 1rem;
            text-align: left;
        }

        .modal-footer {
            padding: 1rem;
            text-align: right;
        }
    }
}
