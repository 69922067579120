.article {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 16rem;

    h1,
    .subtitle {
        text-align: center;
    }

    p {
        line-height: 1.6;
    }

    .subtitle {
        font-size: var(--f-size-xs);
        text-transform: uppercase;
        margin-bottom: 2rem;

        &.movement {
            color: var(--c-blue-200);
        }

        &.nutrition {
            color: var(--c-magenta-300);
        }

        &.health {
            color: var(--c-purple-300);
        }
    }

    img {
        border-radius: 1rem;
    }

    .slider {
        width: 100%;
        aspect-ratio: 1;
        border-radius: 1rem;

        .bullet {
            opacity: 1; // temp
        }
        .bulletActive {
            opacity: 1; // temp
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
