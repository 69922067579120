.container {
  width: 100%;


  display: flex;
  justify-content: stretch;
  align-items: center;

  button {
    width: 100%;
    height: 3.6rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background: transparent;
    border: 1px solid var(--c-purple-400);

    color: var(--c-purple-400);
    font-size: var(--f-size-sm);
    font-family: var(--f-main);
    font-weight: var(--f-semi-bold);

    transition: background-color 300ms ease-in-out, color 300ms ease-in-out;

    &:not(:first-of-type) {
      margin-left: -1px;
    }

    &:first-of-type {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 0.8rem;
    }

    &:last-of-type {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;

      // compensate margin-left: -1px;
      width: calc(100% + 1px);
    }

    &:focus {
      outline: 0.4rem solid var(--c-purple-600);
      color: var(--c-purple-200)
    }

    &.selected {
      background: var(--c-purple-400);
      color: var(--c-white);
      z-index: 1;
    }
  }
}

.dark {
  &.container {
    button {
      &:focus {
        outline: 0.4rem solid var(--c-purple-600);
        color: var(--c-purple-200)
      }
    }
  }
}

.light {
  &.container {
    button {
      &:focus {
        outline: 0.4rem solid var(--c-purple-100);
        color: var(--c-purple-800);

        &.selected {
          color: var(--c-white);
        }
      }
    }
  }
}