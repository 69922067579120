.options-modal {
    position: relative;
    width: 96%;
    min-height: 72vh;
    background: var(--c-bluish-purple-900);
    position: fixed;
    bottom: -100vh;
    left: 2%;
    padding: 4.2rem 2.4rem;

    z-index: 2;

    border-top-left-radius: 1.6rem;
    border-top-right-radius: 1.6rem;
    box-shadow: 0 -2rem 10rem 0.8rem rgba(0, 0, 0, 0.7);

    transition: bottom 300ms ease-in-out;

    &.dark {
        background: var(--c-bluish-purple-900);
    }

    &.light {
        background: var(--c-white);
    }

    .close-button {
        position: absolute;
        top: 1rem;
        right: 1rem;

        svg {
            fill: var(--c-purple-300);
        }
    }

    .button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 1.2rem;

        padding: 3.6rem 1.6rem;

        .option-button {
            width: 100%;
            height: 4.8rem;

            font-size: var(--f-size-sm);
            border: none;
            border-radius: 0.8rem;

            line-height: 0;

            &.dark {
                color: var(--c-white);
                background: var(--c-bluish-purple-800);
            }

            &.light {
                color: var(--c-bluish-purple-500);
                background: var(--c-bluish-purple-50);
            }
        }
    }

    &.show {
        bottom: 0;
    }
}
