.button {
    font-size: var(--f-size-md);
    color: var(--c-white);
    font-weight: var(--f-semi-bold);
    line-height: 1.2;
    text-align: center;

    height: 6.4rem;
    border: none;
    border-radius: 1rem;
    padding: 0 1.6rem;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: filter 300ms ease, background-color 300ms ease;

    &.full {
        width: 100%;
    }

    &.dark {
        background: var(--c-bluish-purple-800);

        &:focus {
            outline: none;
            filter: brightness(1.5);
        }

        &.selected {
            background: var(--c-white);
            color: var(--c-bluish-purple-900);
        }
    }

    &.light {
        color: var(--c-royal-blue-600);
        background: var(--c-white);

        &:focus {
            outline: none;
            color: var(--c-white);
            background: var(--c-magenta-500);
        }

        &.selected {
            color: var(--c-white);
            background: var(--c-magenta-500);
        }
    }
}
