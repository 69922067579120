.intro {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0;
    margin-top: -6rem;

    .preTitle {
        font-size: var(--f-size-xs);
        color: var(--c-blue-200);
        font-weight: var(--f-semi-bold);
        line-height: 1;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.05rem;
    }

    h1 {
        font-size: var(--f-size-2xl);
        font-weight: var(--f-semi-bold);
    }

    .rating {
        margin: 2rem 0 4.2rem;

        svg {
            opacity: 0.2;
            display: inline-block;

            path {
                fill: var(--c-blue-400);
            }

            & + svg {
                margin-left: 0.4rem;
            }

            &.active {
                opacity: 1;
            }
        }
    }

    .avatar {
        width: 12rem;
        height: 12rem;
        border-radius: 1.6rem;
        margin-bottom: 3rem;

        img {
            border-radius: 1.6rem;
        }
    }

    .buttonsContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 6rem;
        width: 100%;
        margin-top: 2.4rem;

        .languages,
        .social {
            display: flex;
            gap: 1rem;
        }

        .languages {
            justify-content: flex-end;
        }

        .social {
            justify-content: flex-start;

            .tag {
                padding: 0.4rem;

                svg {
                    width: 90%;
                    height: 90%;

                    path {
                        fill: var(--c-blue-200) !important;
                    }
                }
            }
        }
    }

    .bio {
        margin: 4.2rem 0;

        h3 {
            font-size: var(--f-size-xl);
            font-weight: var(--f-light);
            color: var(--c-blue-200);
            text-align: center;
            margin-bottom: 6.4rem;

            &::before,
            &::after {
                font-weight: var(--f-semi-bold);
            }

            &::before {
                content: '" ';
            }

            &::after {
                content: ' "';
            }
        }

        h2 {
            margin-bottom: 1rem;
        }

        p {
            font-weight: var(--f-light);
            line-height: 1.4;

            & + h2 {
                margin-top: 4.2rem;
            }
        }
    }
}

.specialty {
    margin-top: 6.2rem;

    h2 {
        margin-bottom: 3.2rem;
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
    }
}

.services {
    margin-top: 9rem;

    h2 {
        margin-bottom: 3.2rem;
    }

    .carousel {
        margin-bottom: 2.4rem;
    }

    .disclaimer {
        display: inline-block;
        font-size: var(--f-size-xs);
        font-weight: var(--f-light);
        text-align: center;
        letter-spacing: 0.05rem;
        line-height: 1.3;

        &.dark {
            color: var(--c-neutral-100);
        }

        &.light {
            color: var(--c-royal-blue-600);
        }
    }
}

.contacts {
    width: 100%;
    margin-top: 10rem;

    .container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;
        margin-top: 3.6rem;

        .icon {
            width: 100%;
            height: 4.2rem;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 100%;
                height: 100%;

                path {
                    fill: var(--c-blue-200);
                }
            }
        }
    }
}

.comments {
    margin-top: 8.2rem;

    h2 {
        margin-bottom: 3rem;
    }
}
