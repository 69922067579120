.activities {
    padding: 10rem 0;

    h2 {
        margin-bottom: 2.4rem;
    }

    .activity {
        display: flex;
        align-items: center;
        gap: 2rem;

        .content {
            flex: 1;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            strong {
                font-weight: var(--f-semi-bold);
                text-transform: capitalize;
            }

            span {
                font-size: var(--f-size-sm);
                color: var(--c-neutral-200);
                line-height: 1.2;
            }
        }

        .icon {
            width: 2rem;
            height: 2rem;
            padding: 0.4rem;
            align-self: flex-end;

            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 0.4rem;
            margin-bottom: 1.6rem;
            background: var(--c-magenta-500);

            svg {
                width: 100%;
                height: 100%;

                path {
                    fill: var(--c-white);
                    stroke: var(--c-white);
                }
            }
        }

        &+.activity {
            margin-top: 2rem;
        }
    }
}