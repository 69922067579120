.container {
    display: block;
    position: relative;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    border: 1px solid var(--c-white);
    border-radius: 1rem;
    height: 6rem;

    & > * {
        color: inherit;
        font-family: inherit;
        font-size: inherit;
    }

    label {
        display: none;
    }

    select {
        width: 100%;
        height: 6rem;
        font-size: var(--f-size-md);
        line-height: 1;
        box-sizing: border-box;
        border: none;
        color: var(--c-white);
        background: transparent;
        text-align: center;
        text-align-last: center;
        appearance: none;
        -webkit-appearance: none;

        &:focus {
            outline: none;

            & + .holder {
                outline: 2px solid var(--c-red-200);
            }
        }
    }

    .holder {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: var(--f-size-md);
        color: var(--c-white);
        text-align: center;
        background: var(--c-bluish-purple-900);
        border-radius: 1rem;

        pointer-events: none;

        .label {
            position: absolute;
            top: 0.4rem;
            left: 1rem;
            font-size: var(--f-size-sm);
        }
    }
}
