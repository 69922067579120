.intro {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0;
    margin-top: -6rem;

    .preTitle {
        font-size: var(--f-size-xs);
        color: var(--c-bluish-purple-200);
        font-weight: var(--f-semi-bold);
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: 0.05rem;
        margin-bottom: 0.5rem;
    }

    h1 {
        font-size: var(--f-size-2xl);
        font-weight: var(--f-semi-bold);
        text-align: center;
    }

    .button {
        position: relative;
        margin: 8rem 0 2rem;
        color: var(--c-purple-600);
        font-family: var(--f-main);
        font-weight: var(--f-semi-bold);
        line-height: 1;
        letter-spacing: 0.04rem;
        text-decoration: none;
        background: var(--c-white);
        border: none;

        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 0.8rem;
        overflow: hidden;
        z-index: 1;

        transition: transform 200ms ease-in-out;

        height: 5.2rem;
        padding: 0 3.6rem;
        font-size: var(--f-size-lg);
        border-radius: 1.6rem;

        span {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 0.8rem;

            svg {
                width: 1.6rem;
                height: 1.6rem;

                path {
                    fill: var(--c-purple-600);
                }
            }
        }

        &.health {
            color: var(--c-white);
            background: var(--c-purple-600);

            svg path {
                fill: var(--c-white);
            }

            &::after {
                background: var(--c-white);
            }
        }
    }

    p {
        font-size: var(--f-size-sm);
        font-weight: var(--f-light);
        letter-spacing: 0.05rem;
    }
}

.description {
    margin-top: 8rem;

    h2 {
        margin-bottom: 1rem;
    }

    p,
    li {
        font-weight: var(--f-light);
        letter-spacing: 0.05rem;
        line-height: 1.4;
    }

    p {
        margin-bottom: 8rem;
    }

    &.dark {
        p {
            color: var(--c-neutral-100);
        }
    }

    &.light {
        p {
            color: var(--c-neutral-800);
        }
    }

    ul,
    ol {
        padding-left: 2rem;
        margin-bottom: 6rem;
    }

    li {
        margin-bottom: 1rem;
    }

    .highlight {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--c-purple-600);
        margin: 4rem 0 8rem;
        padding: 1rem;
        text-align: center;
        border-radius: 0.8rem;

        &.light {
            color: var(--c-white);
        }
    }

    .footnote {
        font-size: var(--f-size-sm);
        font-weight: var(--f-light);
        text-align: center;
    }
}
