.card {
    position: relative;
    width: 100%;
    min-height: 34rem;
    perspective: 60rem;
    margin: 2rem 0;

    .face {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 9999;
        box-sizing: border-box;
        border-radius: 2rem;

        transition: transform 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transform-style: preserve-3d;
        backface-visibility: hidden;

        &.front {
            position: relative;
            transform: rotateY(0);
            background: var(--c-white);
            padding: 1rem;
            overflow: hidden;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .header {
                .dateContainer {
                    & > span {
                        display: block;
                        text-align: center;
                        color: var(--c-royal-blue-600);

                        &.weekday {
                            font-size: 2rem;
                            font-weight: var(--f-semi-bold);
                            line-height: 1.2;
                        }

                        &.date {
                            font-size: 1.4rem;
                            line-height: 1.2;
                        }
                    }
                }
            }

            .body {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                padding-top: 2rem;
                box-sizing: border-box;

                @media screen and(min-height: 660px) {
                    height: 30rem;
                    padding-top: 4rem;
                }

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .icon {
                        width: 12rem;
                        height: 12rem;
                        aspect-ratio: 1;
                        object-fit: cover;
                        border-radius: 2.6rem;

                        &.swirl-in-fwd {
                            -webkit-animation: swirl-in-fwd 0.6s ease-out 1s both;
                            animation: swirl-in-fwd 0.6s ease-out 1s both;
                        }

                        &.tilt-in-right-1 {
                            -webkit-animation: tilt-in-right-1 0.6s
                                cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
                            animation: tilt-in-right-1 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)
                                both;
                        }

                        &.tilt-in-left-1 {
                            -webkit-animation: tilt-in-left-1 0.6s
                                cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
                            animation: tilt-in-left-1 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
                        }
                    }

                    .addButton {
                        width: 12rem;
                        height: 12rem;
                        background: var(--c-white);
                        border: none;
                        border-radius: 2.6rem;
                        padding: 2rem;
                        cursor: pointer;

                        svg {
                            width: 100%;
                            height: 100%;
                            stroke: var(--c-blue-400);
                        }
                    }

                    .wrapper {
                        margin-top: 1.6rem;

                        span {
                            color: var(--c-royal-blue-600);
                            line-height: 1.2;
                        }

                        &.swing-in-bottom-fwd {
                            -webkit-animation: swing-in-bottom-fwd 0.5s
                                cubic-bezier(0.175, 0.885, 0.32, 1.275) 1s both;
                            animation: swing-in-bottom-fwd 0.5s
                                cubic-bezier(0.175, 0.885, 0.32, 1.275) 1s both;
                        }
                    }

                    .activity {
                        font-weight: var(--f-semi-bold);
                    }

                    .duration {
                        margin: 0;
                    }
                }

                .points {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    margin: 0;

                    font-size: 2rem;
                    font-weight: var(--f-bold);
                    color: var(--c-blue-400);

                    svg {
                        path {
                            fill: var(--c-blue-400);
                        }
                    }
                }

                .helper {
                    color: var(--c-royal-blue-600);
                }
            }

            .nav {
                position: absolute;
                bottom: 0.4rem;
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding-top: 2rem;

                & > button {
                    border: none;
                    background: transparent;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 4rem;
                    cursor: pointer;

                    svg {
                        width: 100%;
                        height: 4rem;
                        stroke: var(--c-blue-400);
                    }

                    &:disabled {
                        cursor: default;

                        svg {
                            opacity: 0.2;
                            stroke: var(--c-neutral-100);
                        }
                    }
                }
            }
        }

        &.back {
            transform: rotateY(-180deg);
            background: var(--c-blue-400);
            padding: 1rem;

            .body {
                position: relative;
                padding: 3.6rem 2rem 2rem 2rem;
                box-sizing: border-box;
                height: 100%;

                form {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: stretch;

                    .select {
                        background: var(--c-blue-400);
                    }

                    .score {
                        height: 3rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        color: var(--c-white);
                        font-size: var(--f-size-xl);
                        font-weight: var(--f-bold);
                        letter-spacing: 0.05rem;

                        & > svg {
                            margin-right: 1rem;

                            path {
                                fill: var(--c-white);
                            }
                        }
                    }

                    .button {
                        align-self: center;
                        color: var(--c-blue-400);
                    }
                }

                .backButton {
                    position: absolute;
                    bottom: 2rem;
                    left: 1rem;

                    svg {
                        width: 1.6rem;
                        height: 1.6rem;
                    }
                }
            }
        }

        &.light {
            border: 1px solid var(--c-neutral-100);
        }
    }

    &.flipped {
        animation-play-state: running;
        .front {
            transform: rotateY(180deg);
        }

        .back {
            transform: rotateY(0);
        }
    }
}

@-webkit-keyframes swirl-in-fwd {
    0% {
        -webkit-transform: rotate(-540deg) scale(0);
        transform: rotate(-540deg) scale(0);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
        opacity: 1;
    }
}
@keyframes swirl-in-fwd {
    0% {
        -webkit-transform: rotate(-540deg) scale(0);
        transform: rotate(-540deg) scale(0);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
        opacity: 1;
    }
}

@-webkit-keyframes swing-in-bottom-fwd {
    0% {
        -webkit-transform: rotateX(100deg);
        transform: rotateX(100deg);
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
        opacity: 1;
    }
}
@keyframes swing-in-bottom-fwd {
    0% {
        -webkit-transform: rotateX(100deg);
        transform: rotateX(100deg);
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
        opacity: 1;
    }
}

@-webkit-keyframes tilt-in-right-1 {
    0% {
        -webkit-transform: rotateX(-30deg) translateX(300px) skewX(30deg);
        transform: rotateX(-30deg) translateX(300px) skewX(30deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
        transform: rotateX(0deg) translateX(0) skewX(0deg);
        opacity: 1;
    }
}
@keyframes tilt-in-right-1 {
    0% {
        -webkit-transform: rotateX(-30deg) translateX(300px) skewX(30deg);
        transform: rotateX(-30deg) translateX(300px) skewX(30deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
        transform: rotateX(0deg) translateX(0) skewX(0deg);
        opacity: 1;
    }
}

@-webkit-keyframes tilt-in-left-1 {
    0% {
        -webkit-transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
        transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
        transform: rotateX(0deg) translateX(0) skewX(0deg);
        opacity: 1;
    }
}
@keyframes tilt-in-left-1 {
    0% {
        -webkit-transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
        transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
        transform: rotateX(0deg) translateX(0) skewX(0deg);
        opacity: 1;
    }
}
