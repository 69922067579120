:root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
}

body {
    background: var(--c-background);
    color: var(--c-element);
    min-height: calc(100 * var(--v-height));

    transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
}

main {
    padding-bottom: 14rem;
    // min-height: 200vh;
}

.container {
    width: 96%;
    max-width: 34rem;
    margin: 0 auto;
}
