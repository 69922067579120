.modal {
    text-align: center !important;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    .icon {
        width: 7.4rem;
        height: 7.4rem;
        fill: var(--c-magenta-500) !important;
    }

    strong {
        color: var(--c-magenta-500);
    }

    p {
        color: var(--c-neutral-700);
        font-size: var(--f-size-md);
        font-weight: var(--f-light);
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: center;

        color: var(--c-magenta-500);
        font-weight: var(--f-semi-bold);
        line-height: 0;

        border: 1px solid var(--c-magenta-500);
        border-radius: 1rem;

        height: 3.6rem;
        padding: 0 2.4rem;
        margin: 2.4rem 0;

        transition: border 200ms ease-in-out, background-color 200ms ease-in-out,
            color 200ms ease-in-out;

        &:hover {
            border-color: var(--c-magenta-500);
            background: var(--c-magenta-500);
            color: var(--c-white);
        }
    }
}
