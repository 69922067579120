.icon {
    width: 18rem;
    height: 18rem;
    fill: var(--c-white);
    position: relative;

    .outerRing,
    .innerRing {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .outerRing {
        -webkit-animation: rotate-center 12s linear infinite both;
        animation: rotate-center 12s linear infinite both;
    }

    .innerRing {
        -webkit-animation: rotate-center 12s linear infinite reverse both;
        animation: rotate-center 12s linear infinite reverse both;
    }
}

@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: translate(-50%, -50%) rotate(0);
        transform: translate(-50%, -50%) rotate(0);
    }
    100% {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
        -webkit-transform: translate(-50%, -50%) rotate(0);
        transform: translate(-50%, -50%) rotate(0);
    }
    100% {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
