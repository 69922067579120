.player-container {
    margin-top: calc(2.5 * var(--safe-area-inset-top));

    .player {
        width: 100%;
        height: 24rem;
        object-fit: cover;
        display: none;

        &.show {
            display: block;
        }
    }

    .player-card {
        position: relative;
        height: 24rem;
        border: none;
        display: none;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .icon {
            position: absolute;
            bottom: 50%;
            right: 50%;
            transform: translate(50%, 50%);
            opacity: 0.8;
        }

        &.show {
            display: block;
        }
    }
}

.player-placeholder {
    width: 100%;
    height: 24rem;
    display: flex;
    margin-top: -7rem;

    &.dark {
        background: var(--c-bluish-purple-800);
    }

    &.light {
        background: var(--c-white);
    }
}

.player-loading {
    width: 100%;
    height: 24rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-image: var(--g-gradient-movement-primary);
}

.primary-details {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: var(--f-size-sm);
    text-transform: uppercase;
    padding: 1.2rem 0.8rem;
    letter-spacing: 0.1rem;
    font-weight: var(--f-light);

    &.dark {
        color: var(--c-white);
        background: var(--c-bluish-purple-800);
    }

    &.light {
        color: var(--c-bluish-purple-800);
        background: var(--c-white);
    }
}

.details {
    padding: 2rem 0;

    .pre-title {
        font-size: var(--f-size-xs);
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        color: var(--c-blue-200);
    }

    .sub-title {
        display: inline-block;
        margin-bottom: 1rem;
        letter-spacing: 0.1rem;
    }

    .description {
        margin-bottom: 2.4rem;
    }

    .specs {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;

        .label {
            letter-spacing: 0.1rem;
            font-weight: var(--f-semi-bold);
        }

        .tags-holder {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;
        }

        & + .specs {
            margin-top: 2rem;
        }
    }
}

.recommended {
    margin-top: 4.2rem;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;

    .card-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 3.6rem;

        margin-top: 2rem;
    }

    .back-button {
        align-self: center;
        margin: 4.2rem 0;
    }
}
