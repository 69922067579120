.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.6rem;

    .points,
    .label {
        color: var(--c-white);
        letter-spacing: 0.05rem;
    }

    .points {
        font-size: var(--f-size-2xl);
        font-weight: var(--f-light);
        line-height: 1;
    }

    .label {
        font-size: var(--f-size-xs);
        font-weight: var(--f-regular);
        text-transform: capitalize;
    }
}
