.filter {
    width: 40%;
    position: relative;

    & > button {
        color: var(--c-white);
        width: 100%;
        background: transparent;
        border: none;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 1.4rem;
        text-transform: uppercase;
    }

    svg {
        margin-left: 0.4rem;
        width: 1.8rem;
        height: 2rem;
        transform: rotate(180deg);

        transition: transform 200ms ease;
    }

    .list {
        width: 18rem;
        overflow: hidden;
        padding: 0;
        border-radius: 1rem;
        position: absolute;
        top: 110%;
        right: 0;
        z-index: -1;

        height: 0;

        transition: height 200ms ease;

        button {
            width: 100%;
            height: 4.8rem;

            border: none;
            background: transparent;
            padding: 0.4rem 0;

            font-size: var(--f-size-xs);
            text-align: left;
            line-height: 1.2;
            letter-spacing: 0.05rem;

            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }

    &.show {
        svg {
            transform: rotate(0deg);
        }

        .list {
            padding: 1rem;
            height: 24rem;
            overflow-y: scroll;
            z-index: 5;
        }
    }

    &.dark {
        button {
            color: var(--c-white);
        }

        svg path {
            fill: var(--c-white) !important;
        }

        .list {
            background: var(--c-bluish-purple-800);

            button + button {
                border-top: 1px solid var(--c-bluish-purple-900);
            }
        }

        &.show .list {
            box-shadow: 0 0 3.6rem 2rem rgba(0, 0, 0, 0.5);
        }
    }

    &.light {
        button {
            color: var(--c-royal-blue-600);
        }

        svg path {
            fill: var(--c-royal-blue-600) !important;
        }

        .list {
            background: var(--c-neutral-50);

            button + button {
                border-top: 1px solid var(--c-white);
            }
        }

        &.show .list {
            box-shadow: 0 0 3.6rem 2rem rgba(0, 0, 0, 0.2);
        }
    }
}
