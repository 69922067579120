.menu {
    position: fixed;
    top: 0;
    right: -110vw;
    width: 100%;
    height: 100vh;
    padding: 1.8rem;
    background: var(--c-purple-600);
    z-index: 1000;

    transition: right 300ms ease;

    .menu-head {
        position: relative;
        width: 100%;
        height: 6.8rem;
        padding: calc(1rem + var(--safe-area-inset-top)) 0 1rem;
        margin-bottom: 3.2rem;

        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 0.5rem;

        .user {
            flex: 1;
            display: grid;
            grid-template-columns: 4.8rem auto;
            grid-template-rows: 1fr 1fr;
            column-gap: 0.8rem;
            align-items: center;
            grid-template-areas:
                'avatar name'
                'avatar points';

            &>a {
                grid-area: avatar;
                width: 4.8rem;
                height: 4.8rem;
            }

            .name {
                grid-area: name;
                color: var(--c-white);
                font-size: var(--f-size-sm);
                font-weight: var(--f-semi-bold);
                letter-spacing: 0.05rem;
                line-height: 1;

                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            .points {
                grid-area: points;
                color: var(--c-neutral-100);
                font-size: var(--f-size-sm);
                text-transform: uppercase;
                line-height: 1;
            }
        }

        .myhi-button {
            border: 1px solid var(--c-white);
            background: transparent;
            color: var(--c-white);
            padding: 0 1.2rem;
            height: 3.2rem;
        }

        .close-button svg {
            fill: var(--c-white);
        }
    }

    .nav {
        width: calc(100% + 2rem);
        height: 100%;
        padding-right: 2rem;
        overflow-y: scroll;
        padding-bottom: 20rem;
        padding-top: var(--safe-area-inset-top);

        .accordion>button {
            color: var(--c-white);

            svg {
                fill: var(--c-white) !important;
            }
        }
    }

    .link {
        position: relative;
        display: block;

        color: var(--c-white);
        font-size: var(--f-size-md) !important;
        text-align: left;
        letter-spacing: 0.05rem;
        font-weight: var(--f-light);

        background: transparent;
        border: none;
        padding: 0.8rem;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--c-white);
            opacity: 0;
        }

        &:focus,
        &.selected {
            font-weight: var(--f-semi-bold);

            &::after {
                opacity: 0.1;
            }
        }

        &:disabled,
        &[disabled] {
            opacity: 0.3;
            cursor: default;
            pointer-events: none;

            &::after {
                content: 'brevemente';
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }

    &.open {
        right: 0;
    }

    .modeButton {
        margin-top: 2rem;
        margin-right: 1rem;
    }

    .secondary-links {
        margin-top: 1.6rem;

        .link {
            color: var(--c-neutral-100);
        }
    }
}