.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .lettering {
        width: 100%;
        height: 100%;
    }
}
