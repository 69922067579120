:root {
    --c-hi-blue: #00a0df;
    --c-hi-purple: #b11cab;

    --c-black: #000;
    --c-white: #fff;

    --c-neutral-900: #0c0c13;
    --c-neutral-800: #29292e; // previous `--c-neutral-700 : #29292e`
    --c-neutral-700: #3b3e44;
    --c-neutral-600: #53575e;
    --c-neutral-500: #6a6f78;
    --c-neutral-400: #848992;
    --c-neutral-300: #a0a3aa;
    --c-neutral-200: #babdc2;
    --c-neutral-100: #dbdde1; // previous `--c-neutral-400 : #dbdde1`
    --c-neutral-50: #f4f6fa; // previous `--c-neutral-200 : #f4f6fa`

    --c-green-900: #001717;
    --c-green-800: #013d3c;
    --c-green-700: #136563;
    --c-green-600: #228c89;
    --c-green-500: #32b3b0;
    --c-green-400: #5ad1ce; // previous `--c-aqua-blue-200 : #5ad1ce`
    --c-green-300: #70d8d6;
    --c-green-200: #97e4e2; // previous `--c-cristal-blue-100 : #9fe4e1`
    --c-green-100: #bbf0ee;
    --c-green-50: #ddfcfc;

    --c-blue-900: #001021;
    --c-blue-800: #002e51;
    --c-blue-700: #004c82;
    --c-blue-600: #006bb4;
    --c-blue-500: #0089e6;
    --c-blue-400: #1aa3ff; // previous `--c-ocean-blue-300 : #0199ff`
    --c-blue-300: #49b7ff;
    --c-blue-200: #7cccff; // previous `--c-nordic-blue-200 : #80c1fc`
    --c-blue-100: #ace1ff;
    --c-blue-50: #d9f7ff;

    --c-royal-blue-900: #040b17;
    --c-royal-blue-800: #14233a; // previous `--c-night-blue-900 : #14233a`
    --c-royal-blue-700: #23395d;
    --c-royal-blue-600: #325082; // previous `--c-old-navy-blue-800 : #4b5980`
    --c-royal-blue-500: #4167a6;
    --c-royal-blue-400: #5980bf;
    --c-royal-blue-300: #7d9dcd;
    --c-royal-blue-200: #a2b9db;
    --c-royal-blue-100: #c6d5eb;
    --c-royal-blue-50: #e9f1fd;

    --c-bluish-purple-900: #1e0e30; // previous `--c-primary-dark : #1e0e30`
    --c-bluish-purple-800: #281240; // previous `--c-primary-semi-dark : #281240`
    --c-bluish-purple-700: #1e136e;
    --c-bluish-purple-600: #2a1c98;
    --c-bluish-purple-500: #3823c2;
    --c-bluish-purple-400: #513ddc; // previous `--c-purple-mist-500 : #5d4ade`
    --c-bluish-purple-300: #7868e4;
    --c-bluish-purple-200: #9e93ed; // previous `--c-la-vanda-200 : #a18fe8`
    --c-bluish-purple-100: #c5bdf8;
    --c-bluish-purple-50: #ece8ff;

    --c-purple-900: #0f001f;
    --c-purple-800: #26004f;
    --c-purple-700: #400081;
    --c-purple-600: #5900b3; // previous `--c-primary-mid : #5a00b4`
    --c-purple-500: #7302e5;
    --c-purple-400: #8c1bfe;
    --c-purple-300: #a64dff; // previous `--c-primary-light : #a766da`
    --c-purple-200: #bf80ff;
    --c-purple-100: #dab2ff;
    --c-purple-50: #f5e3ff;

    --c-magenta-900: #1b0115;
    --c-magenta-800: #410a34;
    --c-magenta-700: #691554;
    --c-magenta-600: #931f75;
    --c-magenta-500: #bc2a96; // previous `--c-pink-panther-400 : #c62c9e`
    --c-magenta-400: #d543af;
    --c-magenta-300: #e06cc2; // previous `--c-pink-cocktail-200 : #dc7ac3`
    --c-magenta-200: #ea96d4;
    --c-magenta-100: #f5bee6;
    --c-magenta-50: #ffe7fb;

    --c-red-900: #1f0000;
    --c-red-800: #4e0000;
    --c-red-700: #800200;
    --c-red-600: #b20601;
    --c-red-500: #e50e03;
    --c-red-400: #fe221c;
    --c-red-300: #fe504e;
    --c-red-200: #ff8280; // previous `--c-alaska-salmon-300 : #ff8280`
    --c-red-200-a: rgb(255 130 128 / 60%);
    --c-red-100: #feb3b4; // previous `--c-ice-salmon-100 : #feb3b4`
    --c-red-50: #ffe3e2;

    --c-yellow-900: #1d1300;
    --c-yellow-800: #4d3800;
    --c-yellow-700: #805d00;
    --c-yellow-600: #b38204;
    --c-yellow-500: #e5a70c;
    --c-yellow-400: #fec021;
    --c-yellow-300: #ffce4f;
    --c-yellow-200: #ffde86; // previous `--c-yellow-sand-100 : #ffde86`
    --c-yellow-100: #ffeab0;
    --c-yellow-50: #fff8dd;

    // GRADIENT : MOVEMENT
    --g-gradient-movement-primary: radial-gradient(farthest-corner at 0% 100%,
            #5ad1ce,
            #00c7dd,
            #00bbee,
            #00acfb,
            #0199ff);

    --g-gradient-movement-primary-alt: radial-gradient(farthest-corner at 100% 100%,
            #5ad1ce,
            #00c7dd,
            #00bbee,
            #00acfb,
            #0199ff);
    --g-gradient-movement-secondary: radial-gradient(farthest-corner at 0% 100%,
            #1d3a4e,
            #1f3f53,
            #204557,
            #234a5c,
            #255060,
            #295969,
            #2c6272,
            #306c7b,
            #357d8a,
            #3c8e99,
            #439fa6,
            #4db1b3);
    --g-linear-gradient-movement-horizontal: linear-gradient(45deg,
            var(--c-blue-400) 25%,
            var(--c-green-400) 100%);

    // GRADIENT : NUTRITION
    --g-gradient-nutrition-primary: radial-gradient(farthest-corner at 0% 100%,
            #c62c9e,
            #dc3d92,
            #ec5389,
            #f86a82,
            #ff8280);

    --g-gradient-nutrition-primary-alt: radial-gradient(farthest-corner at 100% 100%,
            #c62c9e,
            #dc3d92,
            #ec5389,
            #f86a82,
            #ff8280);

    --g-gradient-nutrition-secondary: radial-gradient(farthest-corner at 0% 100%,
            #2b2447,
            #30254b,
            #35254e,
            #3a2551,
            #402554,
            #49275a,
            #522860,
            #5c2966,
            #6c2b70,
            #7d2c7a,
            #8f2b82,
            #a22a8a);
    --g-linear-gradient-nutrition-horizontal: linear-gradient(45deg,
            var(--c-magenta-400) 25%,
            var(--c-purple-400) 100%);

    // GRADIENT : HEALTH
    --g-gradient-health-primary: radial-gradient(farthest-corner at 0% 100%,
            #0199ff,
            #007ef4,
            #2560e4,
            #443fcf,
            #5a00b4);
    --g-gradient-health-primary-alt: radial-gradient(farthest-corner at 100% 100%,
            #0199ff,
            #007ef4,
            #2560e4,
            #443fcf,
            #5a00b4);

    --g-gradient-health-secondary: radial-gradient(farthest-corner at 0% 100%,
            #1e2851,
            #202957,
            #222b5c,
            #242c62,
            #272d67,
            #2a3071,
            #2e327a,
            #323584,
            #393995,
            #403da6,
            #4941b7,
            #5344c8);
    --g-linear-gradient-health-horizontal: linear-gradient(45deg,
            var(--c-purple-500) 25%,
            var(--c-blue-400) 100%);

    // GRADIENT : MAIN
    --g-gradient-main: radial-gradient(farthest-corner at 0% 100%,
            #b005ce,
            #9f1ccc,
            #8f27c9,
            #7e2dc5,
            #6d32c1,
            #544bce,
            #365ed6,
            #006edb,
            #008ee4,
            #00aae2,
            #4ac2dc,
            #8dd7da);

    &.light {
        --c-element: var(--c-bluish-purple-900);
        --c-background: var(--c-neutral-50);
        --c-toast-element: var(--c-neutral-800);
        --c-toast-background: var(--c-neutral-100);
        --c-toast-progress: var(--c-neutral-200);
        --c-toast-close-button: var(--c-neutral-600);
        color-scheme: light;
    }

    &.dark {
        --c-element: var(--c-white);
        --c-background: var(--c-bluish-purple-900);
        --c-toast-element: var(--c-neutral-50);
        --c-toast-background: var(--c-neutral-900);
        --c-toast-progress: var(--c-neutral-800);
        --c-toast-close-button: var(--c-neutral-400);
        color-scheme: dark;
    }

    // fallback
    --c-element: var(--c-white);
    --c-background: var(--c-bluish-purple-900);
}

::selection {
    background: var(--c-purple-600);
    color: var(--c-magenta-300);
}

::-moz-selection {
    background: var(--c-purple-600);
    color: var(--c-magenta-300);
}