.slider {
    padding-bottom: 3rem;

    & > div:first-of-type {
        bottom: 0;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .bullet {
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        opacity: 1;
        opacity: 0.4;
        transform: scale(1);

        transition: transform 600ms ease;

        &.dark {
            background: var(--c-white);
        }

        &.light {
            background: var(--c-blue-400);
        }

        &.bulletActive {
            transform: scale(2);
            opacity: 1;
        }

        & + .bullet {
            margin-left: 1.6rem;
        }

        &:focus {
            outline: 2px solid var(--c-red-200);
        }
    }

    .cardFullContainer {
        & > div {
            margin-bottom: 0;
        }

        & > a > div {
            margin-bottom: 0;
        }
    }
}
