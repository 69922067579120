.graph {
    position: relative;
    height: 6rem;

    .labels {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            text-transform: uppercase;
            font-weight: var(--f-semi-bold);
            letter-spacing: 0.05rem;
        }
    }

    .background-bar {
        position: absolute;
        top: calc(50% + 0.3rem);
        left: 0;
        width: 100%;
        height: 1rem;
        border-radius: 0.4rem;
    }

    .progress-bar {
        position: absolute;
        left: 0;
        top: 50%;
        height: 1.6rem;
        border-radius: 0.4rem;

        transition: width 600ms ease-in-out 2000ms;
    }

    .tooltip {
        position: absolute;
        top: -5rem;
        right: 0;
        width: 6rem;
        height: 3.4rem;

        display: flex;
        align-items: center;
        justify-content: center;

        font-weight: var(--f-semi-bold);
        color: var(--c-white);
        line-height: 0;
        padding: 1.2rem;
        border-radius: 1rem;

        opacity: 0;
        transition: opacity 300ms ease-in-out 600ms;

        .triangle {
            position: absolute;
            top: 2.6rem;
            right: 0;

            width: 2rem;
            height: 2rem;
            overflow: hidden;

            div {
                position: absolute;
                top: -0.8rem;
                right: -1rem;

                width: 2rem;
                height: 2rem;
                transform: rotate(45deg);
            }
        }

        &.dark,
        &.light {
            background: var(--c-neutral-500);

            .triangle div {
                background: var(--c-neutral-500);
            }

            &.movement {
                background: var(--c-blue-400);

                .triangle div {
                    background: var(--c-blue-400);
                }

                &.gradient {
                    background: var(--c-green-400);

                    .triangle div {
                        background: var(--c-green-400);
                    }
                }
            }

            &.nutrition {
                background: var(--c-magenta-500);

                .triangle div {
                    background: var(--c-magenta-500);
                }

                &.gradient {
                    background: var(--c-purple-400);

                    .triangle div {
                        background: var(--c-purple-400);
                    }
                }
            }

            &.health {
                background: var(--c-purple-600);

                .triangle div {
                    background: var(--c-purple-600);
                }

                &.gradient {
                    background: var(--c-blue-400);

                    .triangle div {
                        background: var(--c-blue-400);
                    }
                }
            }
        }

        &.show {
            opacity: 1;
        }

        &.reverse {
            transform: scaleX(-1);
            right: unset;

            .counter {
                transform: scaleX(-1);
            }
        }
    }

    .level {
        position: absolute;
        top: calc(40% - 0.1rem);
        right: 0;

        width: 3rem;
        height: 3rem;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 0.4rem;

        font-size: var(--f-size-lg);
        font-weight: var(--f-semi-bold);
        color: var(--c-white);

        background: var(--c-neutral-500);

        &.movement {
            background: var(--c-blue-400);
        }

        &.nutrition {
            background: var(--c-magenta-500);
        }

        &.health {
            background: var(--c-purple-600);
        }
    }

    &.dark,
    &.light {
        .progress-bar {
            background: var(--c-neutral-500);
        }

        &.movement {
            .progress-bar {
                background: var(--c-blue-400);

                &.gradient {
                    background-image: var(--g-linear-gradient-movement-horizontal);
                }
            }
        }

        &.nutrition {
            .progress-bar {
                background: var(--c-magenta-500);

                &.gradient {
                    background-image: var(--g-linear-gradient-nutrition-horizontal);
                }
            }
        }

        &.health {
            .progress-bar {
                background: var(--c-purple-600);

                &.gradient {
                    background-image: var(--g-linear-gradient-health-horizontal);
                }
            }
        }
    }

    &.dark {
        .background-bar {
            background: var(--c-neutral-800);
            box-shadow: 0px 6px 6px -4px var(--c-black);
        }

        &.movement {
            .background-bar {
                background: var(--c-blue-800);
            }
        }

        &.nutrition {
            .background-bar {
                background: var(--c-magenta-800);
            }
        }

        &.health {
            .background-bar {
                background: var(--c-purple-800);
            }
        }
    }

    &.light {
        .background-bar {
            background: var(--c-neutral-100);
            box-shadow: 0px 6px 6px -4px var(--c-neutral-200);
        }

        &.movement {
            .background-bar {
                background: var(--c-blue-50);
            }
        }

        &.nutrition {
            .background-bar {
                background: var(--c-magenta-50);
            }
        }

        &.health {
            .background-bar {
                background: var(--c-purple-50);
            }
        }
    }
}

// DEV
.dev {
    &.graph {
        position: relative;

        &::after {
            content: '';
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(240, 128, 128, 0.2);
        }

        &.labels {
            position: relative;

            &::after {
                content: '';
                pointer-events: none;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(240, 128, 128, 0.2);
            }
        }
    }
}
