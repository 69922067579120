.tag {
  height: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 1rem;
  padding: 0 2.4rem;

  font-size: var(--f-size-xs);
  color: var(--c-white);
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  text-align: center;

  &.payed {
    background: var(--c-green-500);
  }

  &.pending {
    background: var(--c-magenta-500);
  }

  &.overdue {
    background: var(--c-red-500);
  }
}