.intro {
    .logo {
        width: 100%;
        height: 16rem;
        padding: 2.2rem;
        margin-bottom: 6.4rem;

        border-radius: 1.2rem;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
