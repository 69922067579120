.button {
    width: 3rem;
    height: 3rem;
    line-height: 0;
    border: none;
    border-radius: 0.8rem;
    background: var(--c-blue-400);
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: filter 300ms ease;

    svg {
        width: 1.2rem;
        height: 1.2rem;

        &.left {
            transform: rotate(-90deg);
        }

        &.right {
            transform: rotate(90deg);
        }

        &.bottom {
            transform: rotate(180deg);
        }
    }

    &.md {
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 1.6rem;

        svg {
            width: 2.8rem;
            height: 2.2rem;
        }
    }

    &.lg {
        width: 6rem;
        height: 6rem;
        border-radius: 1.6rem;

        svg {
            width: 3.6rem;
            height: 3.4rem;
        }
    }

    &.dark svg {
        fill: var(--c-purple-600);
    }

    &.light svg {
        fill: var(--c-bluish-purple-900);
    }

    &.translucid {
        background: rgba(255, 255, 255, 0.3);
        transition: background-color 300ms ease;

        &:hover {
            background: rgba(255, 255, 255, 0.6);
        }
    }

    &.transparent {
        background: transparent;
    }

    &:hover {
        filter: brightness(1.2);
    }

    &:focus {
        outline: 0.4rem solid var(--c-red-200);
    }

    &:disabled {
        opacity: 0.2;
    }
}
