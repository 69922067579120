.list {
    margin: 5.6rem auto;

    .card + .card {
        margin-top: 5.6rem;
    }
}

.msg {
    background: var(--c-bluish-purple-800);
    margin-top: 5.6rem;
    padding: 4.8rem 1.6rem;

    text-align: center;
}
