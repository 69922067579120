.button {
    border: none;
    padding: 1.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.4rem;

    .icon {
        transform: rotate(90deg);
    }

    &.dark {
        background: var(--c-bluish-purple-800);
        color: var(--c-white);
        box-shadow: 0 5px 12px -6px var(--toastify-color-dark), 5px 5px 15px 5px rgba(0, 0, 0, 0);

        .icon {
            fill: var(--c-white);
        }
    }

    &.light {
        background: var(--c-white);
        box-shadow: 0 5px 12px -6px var(--c-neutral-100), 5px 5px 15px 5px rgba(0, 0, 0, 0);
    }
}
