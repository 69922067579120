.wearables {
    position: relative;
    margin-top: 10rem;

    p {
        margin-bottom: 3.2rem;
    }

    .header {
        display: grid;
        grid-template-columns: calc(100% - 4.8rem - 1rem) 4.8rem;
        gap: 1rem;
        align-items: flex-end;

        .wrapper {
            h2 {
                margin-bottom: 1.6rem;
            }

            p {
                font-size: var(--f-size-sm);
            }
        }

        .share-button {
            width: 4.8rem;
            height: 4.8rem;

            display: flex;
            align-items: center;
            justify-content: center;

            background: var(--c-bluish-purple-800);
            border-radius: 1.6rem;

            svg {
                width: 2.8rem;
                height: 2.2rem;
                fill: var(--c-bluish-purple-200);
            }
        }
    }

    .manual-log-loading {
        width: 100%;
        min-height: 34rem;
        background-image: var(--g-gradient-health-secondary);
        border-radius: 2rem;
    }
}

// 'COMING SOON' LABEL
.wearables {
    &::before {
        font-family: var(--f-main);
        color: var(--c-white);
        font-size: var(--f-size-md);
        font-weight: var(--f-semi-bold);
        line-height: 0;
        letter-spacing: 0.1rem;

        padding: 2.2rem;
        border-radius: 1rem;
        background-color: var(--c-blue-400);

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 50%);

        z-index: 2;
    }

    &.pt::before {
        content: 'brevemente';
    }

    &.en::before {
        content: 'coming soon';
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -1rem;
        right: -1rem;
        height: 28rem;
        opacity: 0.9;
        z-index: 1;
    }

    &.dark {
        &::after {
            background: var(--c-bluish-purple-900);
        }
    }

    &.light {
        &::after {
            background: var(--c-neutral-50);
        }
    }
}
