.drawer {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.1);
    pointer-events: none;

    transition: opacity 300ms ease-in-out;

    z-index: 5;

    &:focus {
        outline: none;
    }

    .dialog {
        position: absolute;
        width: 100%;
        max-width: 45rem;
        min-height: 64vh;
        bottom: -100%;
        left: 50%;
        transform: translateX(-50%);
        overflow: hidden;

        background: var(--c-bluish-purple-900);
        border-top-left-radius: 2.4rem;
        border-top-right-radius: 2.4rem;

        box-shadow: 0 -2rem 10rem 0.8rem rgba(0, 0, 0, 0.7);

        transition: bottom 300ms ease-in-out;

        &:focus {
            outline: none;
        }

        .header {
            margin: 2.4rem 2.4rem 0 2.4rem;
            padding-bottom: 1.2rem;
            border-bottom: 1px solid var(--c-purple-600);
        }

        .body {
            padding: 1.2rem 2.4rem 2.4rem 2.4rem;
        }

        .footer {
            margin: 1.2rem 2.4rem 2.4rem 2.4rem;
        }
    }

    &.show {
        opacity: 1;
        pointer-events: all;

        .dialog {
            bottom: 0;
        }
    }
}
