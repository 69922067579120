.section {
    width: 100%;
    height: calc(100 * var(--v-height));
    padding: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;

    .container {
        width: 94%;
        max-width: 32rem;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        gap: 2rem;

        .logo {
            align-self: center;

            fill: var(--c-white);
            margin-top: var(--safe-area-inset-top);
        }

        h1,
        h2,
        p {
            text-align: center;
            color: var(--c-white);
            margin-bottom: 2.4rem;
        }

        h1 {
            font-size: var(--f-size-2xl);
        }

        h2 {
            font-size: var(--f-size-lg);
        }

        p {
            line-height: 2.4rem;
        }

        .intro {
            &.fade-in {
                -webkit-animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.2s both;
                animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.2s both;
            }
        }

        .body {
            .form {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 2.6rem;

                .input {
                    input {
                        color: var(--c-white);
                    }

                    label {
                        color: var(--c-white) !important;
                    }
                }

                &.fade-in {
                    -webkit-animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
                    animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
                }
            }
        }

        .message {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            height: 100%;
            row-gap: 2.4rem;

            padding: 2.4rem 0;

            .icon {
                width: 12rem;
                height: 12rem;

                display: flex;
                align-items: center;
                justify-content: center;

                & > svg {
                    width: 100%;
                    height: 100%;
                }

                &.swirl-in-fwd {
                    -webkit-animation: swirl-in-fwd 0.6s ease-out both;
                    animation: swirl-in-fwd 0.6s ease-out both;
                }
            }

            .content {
                &.fade-in {
                    -webkit-animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) 0.8s both;
                    animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) 0.8s both;
                }
            }

            .link {
                margin-top: 6.4rem;

                &.external {
                    position: relative;
                    color: var(--c-purple-600);
                    font-family: var(--f-main);
                    font-weight: var(--f-semi-bold);
                    line-height: 0;
                    letter-spacing: 0.04rem;
                    text-decoration: none;
                    cursor: pointer;

                    background: var(--c-white);
                    border: none;

                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    column-gap: 1.6rem;

                    overflow: hidden;
                    z-index: 1;

                    transition: transform 200ms ease-in-out;

                    height: 5.2rem;
                    padding: 0 3.6rem;
                    font-size: var(--f-size-md);
                    border-radius: 1.6rem;

                    span {
                        flex: 1;
                        display: block;
                    }

                    .icon {
                        width: 1.6rem;
                        height: 1.6rem;
                        margin-top: -0.2rem;

                        path {
                            stroke: var(--c-purple-100);
                        }
                    }
                }

                &.fade-in {
                    -webkit-animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) 1.5s both;
                    animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) 1.5s both;
                }
            }
        }

        .register {
            margin-top: 6rem;
            font-size: var(--f-size-sm);

            & > a {
                font-size: var(--f-size-sm);
                font-weight: var(--f-semi-bold);
            }
        }

        .lang-button {
            position: absolute;
            top: calc(2rem + var(--safe-area-inset-top));
            right: 2rem;
            z-index: 1;
        }
    }
}

@media screen and (min-height: 740px) {
    .section {
        .container {
            height: 80%;
        }
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-2-23 16:39:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swirl-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-fwd {
    0% {
        -webkit-transform: rotate(-540deg) scale(0);
        transform: rotate(-540deg) scale(0);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
        opacity: 1;
    }
}
@keyframes swirl-in-fwd {
    0% {
        -webkit-transform: rotate(-540deg) scale(0);
        transform: rotate(-540deg) scale(0);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
        opacity: 1;
    }
}

/**
* ----------------------------------------
* animation fade-in
* ----------------------------------------
*/
@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
