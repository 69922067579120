.cancellation {
    padding: 8.2rem 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    row-gap: 2.6rem;

    h2 {
        text-align: center;
        color: var(--c-green-200);
    }

    p {
        text-align: center;
    }

    .button {
        &.dark {
            color: var(--c-red-200);
            background: var(--c-red-800);
        }

        &.light {
            background: var(--c-red-500);
            color: var(--c-white);
        }
    }

    .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 1.8rem;

        padding: 2.4rem 1.4rem;

        border-radius: 1rem;

        transition: filter 300ms ease-in-out;

        .card-header {
            height: 4.8rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon-container {
                width: 6.4rem;
                height: 6.4rem;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .card-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            row-gap: 1.2rem;

            & > * {
                text-align: center;
            }

            .title {
                font-weight: var(--f-semi-bold);
                font-size: 1.6rem;
                line-height: 1.8rem;
            }

            .message {
                font-size: var(--f-size-sm);
                line-height: 1.6rem;
            }

            .date {
                font-size: var(--f-size-xs);
                line-height: 1.6rem;
                text-transform: uppercase;
            }
        }

        &.dark {
            background: var(--c-bluish-purple-800);
            border: 1px solid var(--c-purple-700);

            .card-header {
                .icon-container {
                    .icon {
                        &.success {
                            stroke: var(--c-green-400);
                        }

                        &.error {
                            stroke: var(--c-red-400);
                        }
                    }
                }
            }

            .card-body {
                .date {
                    color: var(--c-purple-200);
                }
            }
        }

        &.light {
            background: var(--c-white);
            border: 1px solid var(--c-neutral-100);

            .card-header {
                .icon-container {
                    .icon {
                        &.success {
                            stroke: var(--c-green-400);
                        }

                        &.error {
                            stroke: var(--c-red-400);
                        }
                    }
                }
            }

            .card-body {
                .date {
                    color: var(--c-purple-300);
                }
            }
        }
    }
}

.modal-header {
    height: 14.2rem;
    justify-content: center;
    text-align: center;

    & > div {
        justify-content: center !important;
        font-weight: var(--f-light);
        text-align: center;
    }

    button {
        position: absolute;
        top: 0;
        right: 0;
    }

    &.dark > div {
        color: var(--c-red-500);
    }

    &.light > div {
        color: var(--c-red-500);
    }
}

.modal {
    padding: 0;

    .modal-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        row-gap: 2.2rem;
        height: 36rem;

        p {
            font-weight: var(--f-semi-bold);
            line-height: 1.6;
        }

        span {
            line-height: 1.2;
            font-size: var(--f-size-sm);
        }

        .options {
            .radio-btn {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                column-gap: 1rem;

                height: 3.6rem;

                input[type='radio'] {
                    /* Add if not using autoprefixer */
                    -webkit-appearance: none;
                    appearance: none;
                    margin: 0;
                    font: inherit;
                    color: currentColor;
                    width: 1.15em;
                    height: 1.15em;
                    border: 0.15em solid currentColor;
                    border-radius: 50%;
                    transform: translateY(-0.075em);
                    display: grid;
                    place-content: center;

                    &::before {
                        content: '';
                        width: 0.65em;
                        height: 0.65em;
                        border-radius: 50%;
                        transform: scale(0);
                        // box-shadow: inset 1em 1em var(--c-white);

                        transition: 120ms transform ease-in-out;
                    }

                    &:checked {
                        &::before {
                            transform: scale(1);
                        }

                        & + label {
                            font-weight: var(--f-semi-bold);
                        }
                    }
                }

                &:last-of-type {
                    margin-top: 2.2rem;
                }
            }
        }

        .button {
            align-self: flex-end;
            transition: opacity 200ms ease-in-out;

            &:disabled {
                opacity: 0.1;
            }

            &.submit {
                align-self: center;
            }
        }

        .note {
            font-size: var(--f-size-xs);
            font-weight: var(--f-light);
            text-align: center;
        }

        &.dark {
            background: var(--c-purple-700);

            .options {
                .radio-btn {
                    input[type='radio'] {
                        /* For iOS < 15 to remove gradient background */
                        /* Not removed via appearance */
                        background-color: var(--c-purple-700);

                        &::before {
                            box-shadow: inset 1em 1em var(--c-white);
                        }
                    }
                }
            }

            .button {
                &.submit {
                    background: var(--c-red-600);
                    color: var(--c-white);
                }
            }

            .note {
                color: var(--c-purple-100);
            }
        }

        &.light {
            background: var(--c-neutral-50);

            .options {
                input[type='radio'] {
                    /* For iOS < 15 to remove gradient background */
                    /* Not removed via appearance */
                    background-color: var(--c-neutral-50);
                    border-color: var(--c-neutral-100);

                    transition: border-color 200ms ease-in-out;

                    &::before {
                        box-shadow: inset 1em 1em var(--c-purple-400);
                    }

                    &:checked {
                        border-color: var(--c-purple-400);

                        & + label {
                            color: var(--c-purple-400);
                        }
                    }
                }
            }

            .button {
                &.next {
                    background: var(--c-purple-600);
                    color: var(--c-white);
                }

                &.submit {
                    background: var(--c-red-500);
                    color: var(--c-white);
                }
            }

            .note {
                color: var(--c-purple-500);
            }
        }
    }

    .message-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 2rem;

        margin: 0 auto;
        width: 22rem;
        height: 36rem;

        .icon-container {
            width: 7.6rem;
            height: 7.6rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
                width: 100%;
                height: 100%;
            }
        }

        .message {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            row-gap: 1.6rem;

            & * {
                text-align: center;
                line-height: 125%;
                font-weight: var(--f-light);
            }

            .title {
                font-size: var(--f-size-lg);
            }

            .description {
                font-size: var(--f-size-sm);
            }
        }

        .button {
            margin-top: 8rem;
            background: transparent;
            border: none;

            font-weight: var(--f-semi-bold);
            font-size: var(--f-size-sm);
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.12rem;
        }

        &.dark {
            .icon-container {
                .icon {
                    &.success {
                        fill: var(--c-green-400);
                    }

                    &.error {
                        fill: var(--c-red-400);
                    }
                }
            }

            .message {
                .title {
                    &.success {
                        color: var(--c-green-400);
                    }

                    &.error {
                        color: var(--c-red-400);
                    }
                }

                .description {
                    color: var(--c-white);
                }
            }

            .button {
                color: var(--c-purple-300);
            }
        }

        &.light {
            .icon-container {
                .icon {
                    &.success {
                        fill: var(--c-green-400);
                    }

                    &.error {
                        fill: var(--c-red-400);
                    }
                }
            }

            .message {
                .title {
                    &.success {
                        color: var(--c-green-400);
                    }

                    &.error {
                        color: var(--c-red-400);
                    }
                }

                .description {
                    color: var(--c-bluish-purple-800);
                }
            }

            .button {
                color: var(--c-purple-300);
            }
        }
    }
}
