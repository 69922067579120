.promo-section {
    .promo-list {
        margin: 4.6rem 0;

        .promo-card-wrapper {
            .button {
                margin: 4rem auto;

                &:disabled {
                    opacity: 0.2;
                    pointer-events: none;
                }
            }

            & + .promo-card-wrapper {
                margin-top: 8.2rem;
            }
        }
    }
}

.modal {
    min-height: 40rem;
    padding: 0 !important;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 2rem;

    @media screen and (min-height: 731px) and (orientation: portrait) {
        min-height: 46rem;
    }

    .modal-header {
        height: 10rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .modal-body {
        flex: 1;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        gap: 2rem;

        p {
            font-size: var(--f-size-sm);
            line-height: 2rem;
            margin-bottom: 2rem;
        }

        .button {
            display: flex;
            align-items: center;
            justify-content: center;

            height: 5.2rem;
            padding: 0 3.6rem;
            border-radius: 1.6rem;

            font-family: var(--f-main);
            font-size: var(--f-size-md);
            font-weight: var(--f-semi-bold);
            line-height: 1;
            letter-spacing: 0.04rem;
        }

        .external-warning {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;

            p {
                flex: 1;
                width: calc(100% - 3rem);
                font-size: var(--f-size-xs);
                font-weight: var(--f-light);
                line-height: 1.6rem;
                margin: 0;
            }

            .icon {
                width: 2rem;
            }
        }

        .share-message {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-between;
            gap: 2rem;

            p {
                flex: 1;
            }
        }

        &.loading,
        &.redeemed {
            display: flex;
            align-items: center;
            justify-content: center;

            & > * {
                text-align: center;
            }
        }
    }

    .modal-footer {
        min-height: 4.2rem;

        .notes {
            margin-top: 1rem;
            font-size: var(--f-size-xs);
            font-weight: var(--f-light);
            letter-spacing: 0.02rem;
        }
    }
}

.button-link {
    margin: 0 auto;
    display: block;
    border: none;
    background: transparent;

    font-size: var(--f-size-sm);
    font-weight: var(--f-semi-bold);
    text-transform: uppercase;
    letter-spacing: 0.1rem;
}
