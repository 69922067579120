.card {
    width: 100%;
    aspect-ratio: 1;
    border: none;
    border-radius: 1rem;
    padding: 0.8rem;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    & > * {
        color: var(--c-white);
    }

    &.health {
        background-image: var(--g-gradient-health-secondary);
    }
    &.movement {
        background-image: var(--g-gradient-movement-secondary);
    }
    &.nutrition {
        background-image: var(--g-gradient-nutrition-secondary);
    }

    &.health,
    &.movement,
    &.nutrition {
        background-size: 100%;
        background-position: center;

        transition: background-size 1000ms ease;

        &:hover {
            background-size: 300%;
        }
    }

    &:focus {
        outline: 0.4rem solid var(--c-red-200);
    }

    .label {
        font-weight: var(--f-semi-bold);
        font-size: var(--f-size-xs);
        text-transform: uppercase;
        letter-spacing: 0.05rem;
    }

    .points {
        font-weight: var(--f-regular);
        font-size: var(--f-size-xs);
    }
}
