.avatar {
    position: relative;
    background: var(--c-purple-600);
    border-radius: 1rem;
    border: none;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1rem;
        z-index: 1;
    }

    .icon {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        z-index: 0;
        overflow: hidden;

        svg {
            position: absolute;
            bottom: -0.6rem;
            right: 50%;
            transform: translateX(50%);
            width: 100%;
            height: 100%;

            path {
                fill: var(--c-purple-300);
            }
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 0.2rem;
        right: -0.6rem;
        width: 1.2rem;
        height: 1.2rem;
        background: var(--c-green-400);
        border: 2px solid var(--c-white);
        border-radius: 50%;
        z-index: 2;
        opacity: 0;
        transform: scale(0);

        transition: opacity 300ms ease, transform 300ms ease;
    }

    &.notification::after {
        opacity: 1;
        transform: scale(1);
    }
}

.button {
    width: 100%;
    height: 100%;
    display: inline-block;
    border-radius: 1rem;
    padding: 0;

    &:focus {
        outline: 4px solid var(--c-red-200);
    }
}
