.tag {
    box-sizing: border-box;

    letter-spacing: 0.05rem;
    line-height: 0;

    white-space: nowrap;

    border-radius: 1rem;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    transition: filter 200ms ease, border 200ms ease;

    &.xs {
        font-size: 1.2rem;
        height: 2.2rem;
        padding: 0 1rem;

        &.square {
            width: 2.2rem;
            aspect-ratio: 1;
            border-radius: 0.6rem;
        }
    }

    &.sm {
        font-size: var(--f-size-md);
        font-weight: var(--f-semi-bold);
        height: 3rem;
        border-radius: 1.5rem;
        padding: 0.4rem 1rem;

        &.square {
            width: 3rem;
            aspect-ratio: 1;
            border-radius: 0.8rem;
        }
    }

    &.dark {
        color: var(--c-white);
        background: transparent;
        border: 1px solid var(--c-royal-blue-600);
    }

    &.light {
        color: var(--c-neutral-800);
        background: var(--c-white);
        border: 1px solid var(--c-neutral-100);
    }

    &.movement,
    &.nutrition,
    &.health {
        &.outline {
            background: transparent;
        }
        &.fill {
            color: var(--c-white);
        }
    }

    &.movement {
        &.outline {
            color: var(--c-blue-400);
            border-color: var(--c-blue-200);

            &.dark {
                color: var(--c-blue-200);
                border-color: var(--c-blue-400);
            }
        }

        &.fill {
            background: var(--c-blue-400);
            border-color: var(--c-blue-400);
        }
    }

    &.nutrition {
        &.outline {
            color: var(--c-magenta-500);
            border-color: var(--c-magenta-300);

            &.dark {
                color: var(--c-magenta-300);
                border-color: var(--c-magenta-500);
            }
        }

        &.fill {
            background: var(--c-magenta-500);
            border-color: var(--c-magenta-500);
        }
    }

    &.health {
        &.outline {
            color: var(--c-purple-600);
            border-color: var(--c-purple-300);

            &.dark {
                color: var(--c-purple-300);
                border-color: var(--c-purple-600);
            }
        }

        &.fill {
            background: var(--c-purple-600);
            border-color: var(--c-purple-600);
        }
    }

    &.button {
        cursor: pointer;

        &:disabled {
            cursor: not-allowed;
            opacity: 0.8;
        }

        &:hover:not(:disabled) {
            &.light {
                filter: brightness(0.8);
            }

            &.dark {
                filter: brightness(1.3);
            }
        }

        &:focus {
            outline: 2px solid var(--c-red-200);
            border-color: var(--c-red-200);
        }

        &.health,
        &.movement,
        &.nutrition {
            &.outline {
                &:hover {
                    color: var(--c-white);
                }
            }
        }
    }
}
