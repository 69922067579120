.intro {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0.8rem;

    .preTitle {
        font-size: var(--f-size-xs);
        font-weight: var(--f-semi-bold);
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: 0.05rem;
    }

    h1 {
        font-size: var(--f-size-2xl);
        font-weight: var(--f-semi-bold);
    }

    h2 {
        color: var(--c-green-200);
    }

    .avatar {
        margin: 2rem 0;
    }

    .inputFile {
        color: rgba(0, 0, 0, 0);
        width: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &::-webkit-file-upload-button {
            visibility: hidden;
        }

        &::before {
            height: 3.6rem;

            display: inline-flex;
            align-items: center;
            justify-content: center;

            border-radius: 1rem;
            padding: 0 1.6rem;
            outline: none;

            white-space: nowrap;
            user-select: none;
            cursor: pointer;

            font-size: var(--f-size-sm);
            font-family: var(--f-main);
            font-weight: var(--f-semi-bold);
            color: var(--c-purple-600);
            background: var(--c-green-200);
        }

        &.pt::before {
            content: 'Editar';
        }
        &.en::before {
            content: 'Edit';
        }

        &:hover::before {
            border-color: black;
        }

        &::active::before {
            background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
        }
    }

    &.dark {
        .preTitle,
        h2 {
            color: var(--c-green-200);
        }

        .button {
            background: var(--c-green-200);
        }
    }

    &.light {
        .preTitle,
        h2 {
            color: var(--c-green-400);
        }

        .button {
            background: var(--c-green-400);
        }
    }
}

.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;

    & > * {
        text-align: center;
    }

    .button {
        margin-top: 3.6rem;
        background: transparent;
        color: var(--c-red-50);
        border: 1px solid var(--c-red-300);
    }
}
