.nav {
    position: relative;
    width: 96%;
    max-width: 34rem;
    height: 6.8rem;
    margin: -4rem auto 0;
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > a {
        width: 4.8rem;
        height: 4.8rem;
    }

    .back-btn {
        width: 4.8rem;
        height: 4.8rem;

        svg {
            width: 2.2rem;
            height: 2.2rem;
        }

        &.dark svg {
            fill: var(--c-purple-300);
        }

        &.light svg {
            fill: var(--c-purple-600);
        }
    }

    .menu-button {
        &.dark svg {
            fill: var(--c-purple-300);
        }

        &.light svg {
            fill: var(--c-purple-600);
        }
    }
}
