.container {
    position: relative;

    input {
        position: absolute;
        opacity: 0;
        z-index: -1;

        & + label::before {
            content: '';
            display: inline-block;
            margin-right: 0.4rem;
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 0.4rem;
            transition: background-color 200ms ease;
        }

        &:focus + label::before {
            border: 1px solid var(--c-white);
            outline: 0.4rem solid var(--c-red-200-a);
        }
    }

    label {
        font-size: var(--f-size-sm);
        line-height: 1.2;
        cursor: pointer;
        display: grid;
        grid-template-columns: 1.2rem auto;
        gap: 1rem;

        transition: color 200ms ease;

        a {
            color: inherit;

            &:focus {
                outline: 0.4rem solid var(--c-red-200-a);
                outline-offset: 0.4rem;
            }
        }
    }

    &.error {
        label {
            color: var(--c-red-200);

            &::before {
                border-color: var(--c-red-200);
            }
        }
    }

    &.light {
        input {
            & + label::before {
                background: var(--c-white);
                border: 1px solid var(--c-neutral-100);
            }

            &:checked + label::before {
                background: var(--c-red-200);
                border-color: var(--c-red-200);
            }
        }

        label {
            color: var(--c-royal-blue-600);
        }
    }

    &.dark {
        input {
            & + label::before {
                background: var(--c-bluish-purple-900);
                border: 1px solid var(--c-white);
            }

            &:checked + label::before {
                background: var(--c-red-200);
                border-color: var(--c-red-200);
            }
        }

        label {
            color: var(--c-white);
        }
    }
}
