.card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0.8rem 1.6rem;
    grid-template-areas:
        'avatar rating rating rating'
        'avatar comment comment comment'
        'avatar label label label';

    & > div:first-of-type {
        grid-area: avatar;
    }

    .rating {
        grid-area: rating;

        svg {
            opacity: 0.2;

            path {
                fill: var(--c-blue-400);
            }

            &.on {
                opacity: 1;
            }

            & + svg {
                margin-left: 0.3rem;
            }
        }
    }

    .comment {
        grid-area: comment;
        font-size: var(--f-size-sm);
        font-weight: var(--f-light);
        line-height: 1.4;
        letter-spacing: 0.05rem;
        margin: 0;
    }

    .label {
        grid-area: label;
        color: var(--c-blue-200);
        font-size: var(--f-size-xs);
        text-transform: uppercase;
    }

    &.articles {
        & > div:first-of-type {
            background: var(--c-red-100);

            svg path {
                fill: var(--c-yellow-200);
            }
        }

        .rating svg path {
            fill: var(--c-yellow-200);
        }

        .label {
            color: var(--c-yellow-200);
        }
    }

    &.movement {
        & > div:first-of-type {
            background: var(--c-blue-400);

            svg path {
                fill: var(--c-blue-200);
            }
        }

        .rating svg path {
            fill: var(--c-blue-400);
        }

        .label {
            color: var(--c-blue-200);
        }
    }

    &.health {
        & > div:first-of-type {
            background: var(--c-purple-600);

            svg path {
                fill: var(--c-purple-300);
            }
        }

        .rating svg path {
            fill: var(--c-purple-600);
        }

        .label {
            color: var(--c-purple-300);
        }
    }

    &.nutrition {
        & > div:first-of-type {
            background: var(--c-magenta-500);

            svg path {
                fill: var(--c-magenta-300);
            }
        }

        .rating svg path {
            fill: var(--c-magenta-500);
        }

        .label {
            color: var(--c-magenta-300);
        }
    }

    & + .card {
        margin-top: 6.4rem;
    }

    &.dark {
        .comment {
            color: var(--c-white);
        }
    }

    &.light {
        .comment {
            color: var(--c-royal-blue-800);
        }
    }
}
