.intro {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.2rem;
    margin-top: -6rem;

    h1 {
        font-size: var(--f-size-2xl);
        font-weight: var(--f-semi-bold);
    }
}

.partner {
    margin: 4.8rem auto;

    p {
        margin-bottom: 3rem;
    }
}

.points {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    gap: 1.6rem;
    padding: 4.8rem 0;

    .graph {
        transform: scale(0.8);
    }

    .button {
        align-self: center;
    }
}

.tip {
    border-radius: 1.6rem;
    margin: 4rem auto;
    padding: 2rem;
    aspect-ratio: 1;

    animation: gradient 10000ms ease infinite;

    display: flex;
    flex-direction: column;
    justify-content: center;

    h2,
    p {
        color: var(--c-white);
    }

    p {
        margin-bottom: 1rem;
    }

    &.dark {
        background-image: var(--g-gradient-health-secondary);
    }

    &.light {
        background-image: var(--g-gradient-health-primary);
    }
}
@keyframes gradient {
    0%,
    100% {
        background-position: 0% 50%;
        background-size: 400% 400%;
    }
    50% {
        background-position: 100% 50%;
        background-size: 800% 200%;
    }
}

.promos {
    padding-top: 4.8rem;

    h2 {
        margin-bottom: 1rem;
    }
}

.activities {
    padding-top: 6.2rem;

    h2 {
        margin-bottom: 1.6rem;
    }

    p {
        margin-bottom: 4.2rem;
    }
}
