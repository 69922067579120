.card {
    width: 100%;
    height: 0;

    z-index: 5;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;

    margin-top: 2.2rem;
    border-radius: 1rem;
    line-height: 0;
    letter-spacing: 0.05rem;

    opacity: 0;
    transition: opacity 300ms ease-in-out;

    strong {
        display: block;
        width: 100%;
        height: 2rem;
        line-height: 1;
        text-align: center;
    }

    &.dark {
        background: var(--c-bluish-purple-800);
    }

    &.light {
        background: var(--c-neutral-100);
    }

    &.show {
        opacity: 1;
        height: 16rem;
    }
}
