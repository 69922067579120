.navbar {
    width: 96%;
    max-width: 34rem;
    height: 6.2rem;

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    gap: 0.2rem;

    padding: 0 0.8rem;

    overflow: hidden;
    z-index: 4;

    position: fixed;
    bottom: calc(1.6rem + env(safe-area-inset-bottom));
    left: 50%;
    transform: translateX(-50%);

    border-radius: 1.4rem;

    @media screen and (min-width: 375px) {
        width: calc(5 * 7.2rem);
    }

    .link {
        position: relative;
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .icon {
            position: relative;
            width: 100%;
            height: calc(6.2rem - 2.2rem);

            display: flex;
            align-items: flex-end;
            justify-content: center;

            text-align: center;
            padding: 0.2rem;

            svg {
                width: 2.8rem;
                height: 2.8rem;

                path {
                    transition: 200ms ease-in-out;
                }
            }
        }

        .label {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 100%;
            height: 2.2rem;
            padding: 0 0.2rem 0.2rem 0.2rem;

            font-size: 1rem;
            text-transform: uppercase;
            // font-weight: var(--f-light);
            letter-spacing: 0.05rem;
            text-align: center;

            transition: 200ms ease-in-out;

            &.dark {
                color: var(--c-purple-200);
            }

            &.light {
                color: var(--c-magenta-500);
            }
        }

        &.notification {
            &::after {
                content: '';
                position: absolute;
                top: 0.8rem;
                right: 1.4rem;
                width: 1rem;
                height: 1rem;
                border-radius: 0.5rem;
            }

            &.dark::after {
                background-color: var(--c-magenta-400);
            }

            &.light::after {
                background-color: var(--c-purple-500);
            }
        }

        &.selected,
        &:hover,
        &:focus {
            outline: none;
        }

        &.selected {
            .label {
                &.dark {
                    color: var(--c-white);
                }

                &.light {
                    color: var(--c-purple-600);
                }
            }
        }
    }

    &.dark {
        background: var(--c-bluish-purple-800);
        border: 1px solid var(--c-purple-700);
        box-shadow: 0 0 5rem 0 rgba(0, 0, 0, 0.5);

        .link.notification::after {
            box-shadow: 0 0 2rem 1rem rgba(0, 0, 0, 0.5);
        }
    }

    &.light {
        background: var(--c-neutral-50);
        border: 1px solid var(--c-neutral-200);
        box-shadow: 0 0.6rem 1.6rem 0.6rem rgba(0, 0, 0, 0.2);

        .link.notification::after {
            box-shadow: 0 0 1rem 0.2rem var(--c-purple-100);
        }
    }
}
